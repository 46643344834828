import React from 'react';
import commonStyles from "../../assets/styles/common.module.scss";


interface IValidationErrorMsg {
  isValid?: boolean;
  errorMsg: string;
}

const ValidationErrorMsg: React.FC<IValidationErrorMsg> = ({ isValid, errorMsg }) => {

  return (
  <>
    {isValid === false ?
        <p className={`${commonStyles.errorMsg} ${commonStyles.errorMsg__lg}`}>{errorMsg}</p>
      : ""}
  </>
  );
};

export default ValidationErrorMsg;

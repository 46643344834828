import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './formBreadcrumbs.module.scss';
import Breadcrumb from '../breadcrumb/breadcrumb';
import FormType from '../../models/FormType';
import { RootState } from "../../state/store";
import { setFormType } from '../../state/forms/formsSlice';

const FormBreadcrumbs: React.FC = () => {
  const { t } = useTranslation();
  const formType = useSelector((state: RootState) => state.forms.formType);

  const formPageChange = (formType: FormType) => {
    //dispatch(setFormType(formType));
  };

  const dispatch = useDispatch();

  return (
    <div className={styles.wrapper}>
      <Breadcrumb
        step={1}
        text={t('formBreadcrumbs.title-1')}
        selected={formType === FormType.PERSONAL_INFO}
        onClick={() =>
          formPageChange(FormType.PERSONAL_INFO)
        }
      />
      <span className={styles.breadcrumSeparator}>
        <hr />
      </span>
      <Breadcrumb
        step={2}
        text={t('formBreadcrumbs.title-2')}
        selected={formType === FormType.GENERAL_MEDICAL_INFO}
        onClick={() =>
          formPageChange(FormType.GENERAL_MEDICAL_INFO)
        }
      />
      <span className={styles.breadcrumSeparator}>
        <hr />
      </span>
      <Breadcrumb
        step={3}
        text={t('formBreadcrumbs.title-3')}
        selected={formType === FormType.SYMPTOMS}
        onClick={() =>
          formPageChange(FormType.SYMPTOMS)
        }
      />
      <span className={styles.breadcrumSeparator}>
        <hr />
      </span>
      <Breadcrumb
        step={4}
        text={t('formBreadcrumbs.title-4')}
        selected={formType === FormType.READY}
        onClick={() =>
          formPageChange(FormType.READY)
        }
      />
    </div>
  );
};

export default FormBreadcrumbs;

import React from 'react';
import OnBehalfError from '../../features/onBehalfError/onBehalfError';

const OnBehalfErrorPage: React.FC = () => {
  return (
      <div className="container__row">
        <OnBehalfError />
      </div>
  );
};

export default OnBehalfErrorPage;

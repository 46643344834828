import React, { createRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { TuberculosisForm } from "../../models/TuberculosisForm";
import { RootState } from "../../state/store";
import TooltipModal from '../../components/tooltipModal/tooltipModal';
import { setFormType, setTuberculosisInfo } from '../../state/forms/formsSlice';
import FormType from '../../models/FormType';
import { postQuestionnaireResponse } from '../../services/questionnaireService';
import YesNoUnknown from '../../models/YesNoUnknown';
import ValidationErrorMsg from '../../components/validationErrorMsg/validationErrorMsg';
import BottomNavigation from '../../components/bottomNavigation/bottomNavigation';
import Dropdown from '../../components/dropdown/dropdown';
import yearOptions from '../../utils/yearOptions';
import { IOption } from '../../models/IOption';
import InputWithTitle from '@husepidemic/epidemicdesign/inputWithTitle';
import { ConnectedFocusError } from 'focus-formik-error';
import NewRadioGroup from '../../components/newRadioGroup/newRadioGroup';

const GeneralMedicalInfoForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formData = useSelector((state: RootState) => state.forms.tuberculosisForm);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    document.title = t('generalMedicalInfoForm.generalMedicalInfoHeader')
  }, []);

  const initialFocus = createRef<any>();
  useEffect(() => { initialFocus.current?.focus(); }, []);

  const validationSchema = Yup.object().shape({
    uremia: Yup.string()
      .required(t('formValidations.required-field-uremia')),
    dialysisStarted: Yup.string().nullable(),
    organTransplant: Yup.string()
      .required(t('formValidations.required-field-organTransplant')),
    transplantDone: Yup.string().nullable(),
    hiv: Yup.string()
      .required(t('formValidations.required-field-hiv')),
    hivMedication: Yup.number().when("hiv", {
      is: `${YesNoUnknown.Yes}`,
      then: Yup.number()
        .required(t('formValidations.required-field-hivMedication'))
        .typeError(t('formValidations.required-field-hivMedication')),
    }),
    hivMedicationWhen: Yup.string().when("hivMedication", {
      is: `${YesNoUnknown.Yes}`,
      then: Yup.string()
        .required(t('formValidations.required-field-hivMedicationWhen')),
    }),
    cytostaticMedicationInUse: Yup.string()
      .required(t('formValidations.required-field-cytostaticMedicationInUse')),
    cytostaticMedicationDisease: Yup.string().when("cytostaticMedicationInUse", {
      is: (x: string) => parseInt(x) === YesNoUnknown.Yes,
      then: Yup.string()
        .required(t('formValidations.required-field-cytostaticMedicationDisease')),
    }),
    tnfInhibitorMedicationInUse: Yup.string()
      .required(t('formValidations.required-field-tnfInhibitorMedicationInUse')),
    tnfInhibitorMedicationDisease: Yup.string().when("tnfInhibitorMedicationInUse", {
      is: (x: string) => parseInt(x) === YesNoUnknown.Yes,
      then: Yup.string()
        .required(t('formValidations.required-field-tnfInhibitorMedicationDisease')),
    }),
    cortisoneMedicationInUse: Yup.string()
      .required(t('formValidations.required-field-cortisoneMedicationInUse')),
    cortisoneMedicationDisease: Yup.string().when("cortisoneMedicationInUse", {
      is: (x: string) => parseInt(x) === YesNoUnknown.Yes,
      then: Yup.string()
        .required(t('formValidations.required-field-cortisoneMedicationDisease')),
    }),
    silicosis: Yup.string()
      .required(t('formValidations.required-field-silicosis')),
  });

  const handleSubmit = async (values: TuberculosisForm) => {
    setIsValid(true);
    await saveFormData(values);
    dispatch(setFormType(FormType.SYMPTOMS));
  }

  const saveFormData = async (values: TuberculosisForm) => {
    await postQuestionnaireResponse("tuberculosis", values)
      .then(() => {
        dispatch(setTuberculosisInfo(values));
      });
  }

  const [showCytostaticMedicationInUseInfo, setShowCytostaticMedicationInUseInfo] = useState(false);
  const [showTnfInhibitorMedicationInUseInfo, setShowTnfInhibitorMedicationInUseInfo] = useState(false);
  const [showCortisoneMedicationInUseInfo, setShowCortisoneMedicationInUseInfo] = useState(false);
  const [focusOnModal, setFocusOnModal] = useState(false);
  const [focusOnQuestion, setFocusOnQuestion] = useState('');

  useEffect(() => {
    setFocusOnModal(!focusOnModal);
    if (focusOnQuestion === 'cytostaticMedicationInUse') { document.getElementById('cytostaticMedicationInUseHeader')?.focus(); setFocusOnQuestion(''); };
    if (focusOnQuestion === 'tnfInhibitorMedicationInUse') { document.getElementById('tnfInhibitorMedicationInUseHeader')?.focus(); setFocusOnQuestion(''); };
    if (focusOnQuestion === 'cortisoneMedicationInUse') { document.getElementById('cortisoneMedicationInUseHeader')?.focus(); setFocusOnQuestion(''); };
    
  }, [showCytostaticMedicationInUseInfo, showTnfInhibitorMedicationInUseInfo, showCortisoneMedicationInUseInfo])

  const yesNoUnknownItems = [
    {
      label: t('common.yes'),
      value: YesNoUnknown.Yes,
    },
    {
      label: t('common.no'),
      value: YesNoUnknown.No,
    },
    {
      label: t('common.unknown'),
      value: YesNoUnknown.Unknown,
    },
  ];

  return (
    <div id="generalMedicalInfoForm" className="container">
      <h1 ref={initialFocus} tabIndex={-1} className="form-header">{t('generalMedicalInfoForm.generalMedicalInfoHeader')}</h1>
      <h3 className="form-header__sub">{t('generalMedicalInfoForm.generalMedicalInfoSubHeader')}</h3>
      <h5 className="form-header__sub--small">{t('generalMedicalInfoForm.generalMedicalInfoSubHeaderSubText')}</h5>

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values: TuberculosisForm) => {
          await handleSubmit(values);
        }}
      >
        {({ values, setFieldValue, errors, validateForm, handleSubmit }) => (
          <Form noValidate>
            <ConnectedFocusError />
            <div className="container__row">
              <NewRadioGroup
                name='uremia'
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                label={t('generalMedicalInfoForm.uremia')}
                onChange={(e) => {
                  if (parseInt(e) !== YesNoUnknown.Yes) {
                    setFieldValue('dialysisStarted', null);
                  }

                  return parseInt(e);
                }}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            {values.uremia === YesNoUnknown.Yes ?
              <div className="container__row">
                <Dropdown
                  name='dialysisStarted'
                  className='container__col-12 container__col-sm-5'
                  options={yearOptions}
                  value={yearOptions.find(x => x.value === values.dialysisStarted)}
                  onChange={
                    (value: IOption) => {
                      setFieldValue('dialysisStarted', value.value)
                    }}
                  label={t("generalMedicalInfoForm.dialysisStarted")}
                  labelTextClass="form__label"
                />
              </div>
              : ''}
            <div className="container__row">
              <NewRadioGroup
                name='organTransplant'
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                label={t('generalMedicalInfoForm.organTransplant')}
                onChange={(e) => {
                  if (parseInt(e) !== YesNoUnknown.Yes) {
                    setFieldValue('transplantDescription', '');
                    setFieldValue('transplantDone', null);
                  }
                  return parseInt(e);
                }}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            {values.organTransplant === YesNoUnknown.Yes ?
              <div className="container__row">
                <InputWithTitle
                  name='transplantDescription'
                  className='container__col-12 container__col-sm-5 height__min--85 margin__right-pos--5-mobile'
                  label={t('generalMedicalInfoForm.transplantDescription')}
                  value={values.transplantDescription}
                  labelClassName="form__label"
                  inputClassName="form__label--input"
                  maxLength={1000}
                />
                <Dropdown
                  name='transplantDone'
                  className='container__col-12 container__col-sm-5'
                  options={yearOptions}
                  value={yearOptions.find(x => x.value === values.transplantDone)}
                  onChange={
                    (value: IOption) => {
                      setFieldValue('transplantDone', value.value)
                    }}
                  label={t("generalMedicalInfoForm.transplantDone")}
                  labelTextClass="form__label"
                />
              </div>
              : ''}
            <div className="container__row">
              <NewRadioGroup
                name='hiv'
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                label={t('generalMedicalInfoForm.hiv')}
                onChange={(e) => {
                  console.log(JSON.parse(e))
                  if (JSON.parse(e) !== YesNoUnknown.Yes) {
                    setFieldValue('hivMedication', undefined)
                    setFieldValue('hivMedicationWhen', undefined)
                  }
                  return JSON.parse(e)
                }}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            {values.hiv === YesNoUnknown.Yes &&
              <>
              <div className="container__row margin__left-pos--40">
                  <NewRadioGroup
                    name='hivMedication'
                    className='radiobutton'
                    buttonWrapper='radiobutton__row'
                    label={t('generalMedicalInfoForm.hivMedication')}
                    onChange={(e) => parseInt(e)}
                    items={yesNoUnknownItems}
                    required={values.hiv === YesNoUnknown.Yes}
                  />
                </div>
                {values.hivMedication === YesNoUnknown.Yes &&
                  <div className="container__row margin__left-pos--40 margin__bottom-pos--18">
                    <Dropdown
                      name='hivMedicationWhen'
                      className='container__col-12 container__col-sm-5 margin__left-pos--5-mobile'
                      options={yearOptions}
                      value={yearOptions.find(x => x.value === values.hivMedicationWhen)}
                      onChange={
                        (value: IOption) => {
                          setFieldValue('hivMedicationWhen', value.value)
                        }}
                      label={t("generalMedicalInfoForm.hivMedicationWhen")}
                      labelTextClass="form__label"
                      required={values.hivMedication === YesNoUnknown.Yes}
                    />
                  </div>}
              </>
            }
            <div className="container__row">
              <NewRadioGroup
                name='cytostaticMedicationInUse'
                label={t('generalMedicalInfoForm.cytostaticMedicationInUse')}
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                onChange={(e) => {
                  if (parseInt(e) !== YesNoUnknown.Yes) {
                    setFieldValue('cytostaticMedicationDisease', '');
                    setFieldValue('cytostaticMedication', '');
                  }
                  return parseInt(e);
                }}
                items={yesNoUnknownItems}
                required={true}
                iconClick={() => setShowCytostaticMedicationInUseInfo(true)}
              />
              <TooltipModal
                show={showCytostaticMedicationInUseInfo}
                tooltipHeader={t('generalMedicalInfoForm.cytostaticMedicationInUse')}
                tooltipText={t('generalMedicalInfoForm.cytostaticMedicationInUseTooltipInfo')}
                closeButtonText={t('common.close')}
                onCloseButtonClick={() => { setShowCytostaticMedicationInUseInfo(false); setFocusOnQuestion('cytostaticMedicationInUse') }}
                trapModalItems="h2, p, button:not([disabled])"
              />
            </div>
            {values.cytostaticMedicationInUse === YesNoUnknown.Yes ?
              <div className="container__row">
                <InputWithTitle
                  name='cytostaticMedicationDisease'
                  className='container__col-12 container__col-sm-5 margin__right-pos--5-mobile'
                  label={t('generalMedicalInfoForm.cytostaticMedicationDisease')}
                  value={values.cytostaticMedicationDisease}
                  required={true}
                  labelClassName="form__label"
                  inputClassName="form__label--input"
                  errorClassName="form__error"
                  maxLength={1000}
                />
                <InputWithTitle
                  name='cytostaticMedication'
                  className="container__col-12 container__col-sm-5 height__min--85"
                  label={t('generalMedicalInfoForm.cytostaticMedication')}
                  value={values.cytostaticMedication}
                  labelClassName="form__label"
                  inputClassName="form__label--input"
                  maxLength={1000}
                />

              </div>
              : ''}
            <div className="container__row">
              <NewRadioGroup
                name='tnfInhibitorMedicationInUse'
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                label={t('generalMedicalInfoForm.tnfInhibitorMedicationInUse')}
                onChange={(e) => {
                  if (parseInt(e) !== YesNoUnknown.Yes) {
                    setFieldValue('tnfInhibitorMedicationDisease', '');
                    setFieldValue('tnfInhibitorMedication', '');
                  }
                  return parseInt(e);
                }}
                items={yesNoUnknownItems}
                required={true}
                iconClick={() => setShowTnfInhibitorMedicationInUseInfo(true)}
              />
              <TooltipModal
                show={showTnfInhibitorMedicationInUseInfo}
                tooltipHeader={t('generalMedicalInfoForm.tnfInhibitorMedicationInUse')}
                tooltipText={t('generalMedicalInfoForm.tnfInhibitorMedicationInUseTooltipInfo')}
                closeButtonText={t('common.close')}
                onCloseButtonClick={() => { setShowTnfInhibitorMedicationInUseInfo(false); setFocusOnQuestion('tnfInhibitorMedicationInUse'); } }
                trapModalItems="h2, p, button:not([disabled])"
              />
            </div>
            {values.tnfInhibitorMedicationInUse === YesNoUnknown.Yes ?
              <div className="container__row">
                <InputWithTitle
                  name='tnfInhibitorMedicationDisease'
                  className='container__col-12 container__col-sm-5 height__min--85 margin__right-pos--5-mobile'
                  label={t('generalMedicalInfoForm.tnfInhibitorMedicationDisease')}
                  value={values.tnfInhibitorMedicationDisease}
                  required={true}
                  labelClassName="form__label"
                  inputClassName="form__label--input"
                  errorClassName="form__error"
                  maxLength={1000}
                />
                <InputWithTitle
                  name='tnfInhibitorMedication'
                  className="container__col-12 container__col-sm-5 height__min--85"
                  label={t('generalMedicalInfoForm.tnfInhibitorMedication')}
                  value={values.tnfInhibitorMedication}
                  labelClassName="form__label"
                  inputClassName="form__label--input"
                  maxLength={1000}
                />
              </div>
              : null}
            <div className="container__row">
              <NewRadioGroup
                name='cortisoneMedicationInUse'
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                label={t('generalMedicalInfoForm.cortisoneMedicationInUse')}
                onChange={(e) => {
                  if (parseInt(e) !== YesNoUnknown.Yes) {
                    setFieldValue('cortisoneMedicationDisease', '');
                    setFieldValue('cortisoneMedication', '');
                  }
                  return parseInt(e);
                }}
                items={yesNoUnknownItems}
                required={true}
                iconClick={() => setShowCortisoneMedicationInUseInfo(true)}
              />
              <TooltipModal
                show={showCortisoneMedicationInUseInfo}
                tooltipHeader={t('generalMedicalInfoForm.cortisoneMedicationInUse')}
                tooltipText={t('generalMedicalInfoForm.cortisoneMedicationInUseTooltipInfo')}
                closeButtonText={t('common.close')}
                onCloseButtonClick={() => { setShowCortisoneMedicationInUseInfo(false); setFocusOnQuestion('cortisoneMedicationInUse'); }}
                trapModalItems="h2, p, button:not([disabled])"
              />
            </div>
            {values.cortisoneMedicationInUse === YesNoUnknown.Yes ?
              <div className="container__row">
                <InputWithTitle
                  name='cortisoneMedicationDisease'
                  className='container__col-12 container__col-sm-5 height__min--85 margin__right-pos--5-mobile'
                  label={t('generalMedicalInfoForm.cortisoneMedicationDisease')}
                  value={values.cortisoneMedicationDisease}
                  required={true}
                  labelClassName="form__label"
                  inputClassName="form__label--input"
                  errorClassName="form__error"
                  maxLength={1000}
                />
                <InputWithTitle
                  name='cortisoneMedication'
                  className="container__col-12 container__col-sm-5 height__min--85"
                  label={t('generalMedicalInfoForm.cortisoneMedication')}
                  value={values.cortisoneMedication}
                  labelClassName="form__label"
                  inputClassName="form__label--input"
                  maxLength={1000}
                />
              </div>
              : null}
            <div className="container__row">
              <NewRadioGroup
                name='silicosis'
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                label={t('generalMedicalInfoForm.silicosis')}
                onChange={(e) => parseInt(e)}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            <div aria-live="polite" className="container__row">
              <ValidationErrorMsg
                isValid={isValid}
                errorMsg={(t('formValidations.required-fields'))}
              />
            </div>
            <div className="container__row">
              <BottomNavigation
                primaryButtonClick={() => {
                  validateForm().then(() => setIsValid(false));
                  handleSubmit();
                }}
                primaryButtonClass="button__primary"
                primaryButtonLabel={t('buttons.continue')}
                secondaryButtonClick={() => {
                  dispatch(setTuberculosisInfo(values));
                  dispatch(setFormType(FormType.PERSONAL_INFO))
                }}
                secondaryButtonClass="button__secondary"
                secondaryButtonLabel={t('buttons.return')}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default GeneralMedicalInfoForm;

import React, { useState } from 'react';
import styles from "./accordionPanel.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { t } from 'i18next';

interface AccordionProps {
  title: string;
  content: string;
};

const AccordionPanel: React.FC<AccordionProps> = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={styles.accordionItem}>
      <div
        aria-label={`${title} ${isActive ? t('common.open') : t('common.closed')}`}
        tabIndex={0}
        role="button"
        className={styles.accordionTitle}
        onClick={() => {
          setIsActive(!isActive)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsActive(!isActive)
          };
        }}
      >
        <div>{isActive ?
          <FontAwesomeIcon className={styles.accordionArrow} icon={faChevronDown} /> :
          <FontAwesomeIcon className={styles.accordionArrow} icon={faChevronUp} />} <span className={styles.title}>{title}</span></div>
      </div>
      {isActive && <div><div className={styles.accordionContent} dangerouslySetInnerHTML={{ __html: content }} />
        <hr className={styles.divider} />
      </div>
      }
    </div>
  );
};

export default AccordionPanel;

import React, { useEffect, useState } from 'react';
import { Field, useField } from 'formik';
import styles from './newRadioGroup.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

interface IRadioItem {
  label: string;
  value: number | string | boolean;
}

interface IRadioGroup {
  name: string;
  label: string;
  items: IRadioItem[];
  className?: string;
  required?: boolean;
  itemLabelClass?: string;
  itemClass?: string;
  onChange?: (val: string) => number | string | boolean;
  disabled?: boolean;
  labelClass?: string;
  title?: string;
  tooltipText?: string;
  iconClick?: () => void;
  buttonWrapper?: string;
  errorClass?: string;
}

const NewRadioGroup: React.FC<IRadioGroup> = ({
  name,
  items,
  label,
  className,
  required,
  itemLabelClass,
  itemClass,
  onChange,
  disabled,
  labelClass,
  tooltipText,
  iconClick,
  title,
  buttonWrapper,
  errorClass,
}) => {
  const { t } = useTranslation();
  const [field, meta, form] = useField(name);
  const [buttonTitle, setButtonTitle] = useState(`${t("accessibility.infoButtonLabel")} ${label + "."}`);
  const questionHeaderId = name + "Header";


  return (
    <fieldset className={`${className}`}>
      <div className={styles.legendWrapper}>
      <legend
        id={`${name}-label`}
        className={`${styles.label} ${labelClass}`}
      >
        <span aria-hidden="true" className={
          required ? styles.required : ''
        } ></span>
                  <label tabIndex={0} id={questionHeaderId} className={`${styles.label} ${labelClass} ${required ? styles.fontWeight : ''}`}>{label}</label>
      </legend>
      {iconClick && (
            <button
              type="button"
              role="application"
              className={styles.infoButton}
              onMouseEnter={() => setButtonTitle('')}
              onMouseLeave={() => setButtonTitle(`${t("accessibility.infoButtonLabel")} ${label + "."}`)}
              aria-label={buttonTitle}
              onKeyDown={(e) => {
                if ((e.key === ' ' || e.key === 'Enter') && iconClick)
                  iconClick();
              }}>
              <FontAwesomeIcon
                className={styles.infoIcon}
                icon={faInfoCircle}
                size="1x"
                data-for={`tooltip-${name}`}
                data-tip="tooltip"
                onClick={(e) => (iconClick ? iconClick() : null)}
              />
            </button>
        )}
      </div>
      <div className={buttonWrapper}>
        {items.map((item) => (
          <div key={`${name}-${item.value}`} className={styles.fieldWrapper}>
            <Field
              type="radio"
              name={name}
              id={`${name}-${item.label}`}
              required={required}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                form.setValue(onChange ? onChange(e.target.value) : e.target.value)
              }}
              checked={item.value === field.value}
              value={`${item.value}`}
              disabled={disabled}
              aria-describedby={`${name}-error`}
              aria-label={`${label} ${t("accessibility.radioButtonItemLabel")} ${item.label}`}
              aria-required={required}
              aria-invalid={meta.error !== undefined}
            />
            <label
              key={item.label}
              className={`${styles.item} ${itemClass}`}
              htmlFor={`${name}-${item.label}`}
            >
              <span className={`${styles.itemLabel} ${itemLabelClass}`}>
                {item.label}
              </span>
            </label>
          </div>
        ))}
      </div>
      {meta.error &&
        <div id={`${name}-error`} className={`${styles.errorMsg} ${errorClass}`}>{meta.error}</div>
      }
    </fieldset>
  );
};

NewRadioGroup.defaultProps = {
  className: '',
  required: false,
  itemLabelClass: '',
  itemClass: '',
  onChange: (e) => e,
  disabled: false,
  labelClass: '',
  buttonWrapper: '',
  errorClass: '',
};

export default NewRadioGroup;
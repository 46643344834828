import React from 'react';
import styles from "./notificationBar.module.scss";



interface NotificationBarProps {
  textA: string,
  textB: string,
  userFullName: string,
}

const NotificationBar: React.FC<NotificationBarProps> = ({ textA, textB, userFullName }) => {

  return (
    <>
        <div className={`${styles.actOnBehalfContainer}`}>
          <div className={`${styles.actOnBehalfContent}`}>
          {textA}
          <label className={styles.underlinedName}>{userFullName}</label>
          {textB}
          </div>
        </div>
    </>
  );
};

export default NotificationBar;

import axios from "axios";
import { ICommonData } from "../models/ICommonData";
import { IUserDetails } from "../models/IUserDetails";
import userManager from "../utils/userManager";

class CommonDataService {
    public async getCommonData(): Promise<ICommonData|undefined> {
        const user = await userManager.getUser();
        if (!user) return undefined;

        const result = await axios.get(`${process.env.REACT_APP_API_URL}commondata`, {
            headers: {
              Authorization: `${user.token_type} ${user.access_token}`,
            },
        })
        return result.data;
    }

    public async getUserData(): Promise<IUserDetails|undefined> {
        const user = await userManager.getUser();
        if (!user) return undefined;
        
        const userData = await axios.get(`${process.env.REACT_APP_API_URL}me`,{
            headers: {
              Authorization: `${user.token_type} ${user.access_token}`,
            },
        })
        return userData.data;
    }
}

export default new CommonDataService();
import React, { useEffect, useRef } from 'react';
import styles from './logoutWarningBox.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import useInterval from '../../utils/useInterval';
import userManager from '../../utils/userManager';
import dateUtils from '../../utils/dateUtils';
import { logoutCurrentUser, renewLoginTokenCurrentUser } from '../../utils/userManagerHelpers';
import { t } from 'i18next';

interface WarningBoxButton {
  label: string;
  className?: string;
}
interface LogoutWarningBoxProps {
  contentTitle: string;
  helpContent?: string;
  primaryButton: WarningBoxButton;
  secondaryButton: WarningBoxButton;
}

const LogoutWarningBox: React.FC<LogoutWarningBoxProps> = ({
  contentTitle,
  helpContent,
  primaryButton,
  secondaryButton
}) => {
  const initialFocusElement = useRef(null);
  const [logoutWarningTime, setLogoutWarningTime] = React.useState<string>('');
  const [showLogoutWarning, setShowLogoutWarning] = React.useState(false);
  const [logoutWarningSpinner, setLogoutWarningSpinner] = React.useState(false);
  const [buttonsDisabled, setButtonsDisabled] = React.useState(false);
  
  const loginUser = async () => {
    setLogoutWarningSpinner(true);
    setButtonsDisabled(true);
    if (showLogoutWarning) {
      if (!window.confirm(t('logoutWarningBox.unsavedData'))) {
        return;
      }
    }
    await renewLoginTokenCurrentUser();
    setShowLogoutWarning(false);
    setShowLogoutWarning(false);
    setLogoutWarningSpinner(false);
    setButtonsDisabled(false);
  }

  const logoutUser = async () => {
    await logoutCurrentUser();
  }

  const closeButtonClick = () => {
    setShowLogoutWarning(false);
  }

  useInterval(async () => {
    let subscribe = true;
    if (!subscribe) return;
    let tokenTimeOut = await userManager.getUser().then((value) => value?.expires_in);
    if (tokenTimeOut !== undefined) {
      setLogoutWarningTime(dateUtils.formattedTimeFromSeconds(tokenTimeOut))
      //Test timeout with <= 7200 (<2h)
      //Set number 900 (15min)
      if (tokenTimeOut === 900) {
        setShowLogoutWarning(true);
      }
      //Set number 300 (5min)
      else if (tokenTimeOut === 300) {
        setShowLogoutWarning(true);
      }
      //Timeout auto-logout
      else if (tokenTimeOut <= 0) {
        logoutUser();
      }
    }
    return () => {
      subscribe = false;
    };
  }, 1000);

  useEffect(() => {
    if (!showLogoutWarning) return;
      const closeWithEsc = (e: { keyCode: number; }) => {
        if (e.keyCode === 27) {
          closeButtonClick();
        }
      }
    document.addEventListener("keydown", closeWithEsc);
    //Initial focus on header for better accessibility
    (initialFocusElement.current as unknown as HTMLElement).focus();
  }, [showLogoutWarning]);

  return showLogoutWarning ? (
    <div className={styles.logoutWrapper}>
      <div className={styles.logoutContent}>
        <div className="container">
          <h2 ref={initialFocusElement} tabIndex={0} className={`container__col-12 ${styles.logoutContentTitle} hideOutline`}>{`${contentTitle}: ${logoutWarningTime !== '' ? logoutWarningTime + "." : ''}`}</h2>
          <p tabIndex={0} className={styles.logoutHelperContent}>{helpContent}</p>
          <div className={`container__row ${styles.logoutButtons}`}>
            <button
              disabled={buttonsDisabled}
              tabIndex={0}
              className={primaryButton.className}
              type="button"
              onClick={() => loginUser()}
            >
              {logoutWarningSpinner ? <FontAwesomeIcon icon={faSpinner} spin /> : null} {primaryButton.label}
            </button>
            <button
              disabled={buttonsDisabled}
              tabIndex={0}
              className={secondaryButton.className}
              type="button"
              onClick={() => closeButtonClick()}
            >
              {secondaryButton.label}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

LogoutWarningBox.defaultProps = {
  contentTitle: '',
  helpContent: '',
};

export default LogoutWarningBox;

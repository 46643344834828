import axios from "axios";
import { IMaintenance } from "../models/IMaintenance";

class MaintenanceService {
    public async getMaintenanceData(): Promise<IMaintenance[]> {
        const result = await axios.get(`${process.env.REACT_APP_API_URL}maintenance`);
        return result.data;
    }
}

export default new MaintenanceService();
import React, { useEffect, useState } from 'react';
import styles from "./logout.module.scss";
import { logoutHelper } from '../../utils/userManagerHelpers';
import { LOGOUT_KEY } from '../../shared/constants/logoutConstants';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LoggedInUser from '../../models/LoggedInUser';
import { User } from 'oidc-client';
import userManager from '../../utils/userManager';
import { RootState } from "../../state/store";
import { setIsOnBehalf, setLoggedIn } from '../../state/settings/settingsSlice';

const LogOutButton: React.FC<any> = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [currentUser, setCurrentUser] = useState<User>();

    const loggedIn = useSelector((state: RootState) => state.settings.loggedIn);

    useEffect(() => {
        let subscribe = true;

        if (!subscribe) return;
        async function fetchData() {
            const user = await userManager.getUser();

            if (user === null) {
                dispatch(setLoggedIn(LoggedInUser.NO));
            } else {
                if (user.profile?.idp === 'HagPuolesta') {
                    dispatch(setIsOnBehalf(true));
                }

                setCurrentUser(user);
                dispatch(setLoggedIn(LoggedInUser.YES));
            }
        }
        fetchData();

        return () => {
            subscribe = false;
        };
    }, [loggedIn]);

    if (loggedIn === LoggedInUser.UNKNOWN) {
        return <div />;
    }

    if (loggedIn === LoggedInUser.YES)
        return (

            <div
                className={`${styles.logout} container__col-3 ${loggedIn === LoggedInUser.YES ? '' : styles.hidden
                    }`}
            >
                <button
                    type="button"
                    onClick={() => logoutHelper(LOGOUT_KEY, currentUser)}
                >
                    {t('buttons.logout')}
                </button>
            </div>
        );

    if (loggedIn === LoggedInUser.NO) {
        return <div />;
    }

    return <div />;

};

export default LogOutButton;

import * as React from "react";
import commonStyles from "../../assets/styles/common.module.scss";
import Footer from "../../components/footer/footer";
import Header from "../header/header";
import LogoutWarningBox from "../logoutWarningBox/logoutWarningBox";
import { t } from "i18next";

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = (props) => {

  return (
    <>
      <div className={commonStyles.layoutContainer}>
        <div className={commonStyles.header}>
          <Header />
        </div>
        <div className={commonStyles.content}>
          {props.children}
        </div>

        <div className={commonStyles.footer}>
          <Footer />
        </div>
      </div>
      <LogoutWarningBox
        contentTitle={t('logoutWarningBox.titleContent')}
        helpContent={t('logoutWarningBox.helperContent')}
        primaryButton={{
          label: t('logoutWarningBox.reloginBtn'),
          className: "button__primary"
        }}
        secondaryButton={{
          label: t('logoutWarningBox.closeBtn'),
          className: "button__secondary",
        }}
      />
    </>
  );
};

export default Layout;

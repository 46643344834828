import { t } from 'i18next';
import React, { createRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CommonInformationContent from '../../features/commonInformationContent/commonInformationContent';
import TuberculosisPageContent from '../../features/tuberculosis/tuberculosis';
import LoadingStatus from '../../models/enums/LoadingStatus';
import { RootState } from '../../state/store';

const Tuberculosis: React.FC = () => {
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const loadingIndicator = createRef<any>();
  useEffect(() => {
    loadingIndicator.current?.focus();
  }, [userDetails]);

  if (userDetails.fetchingUserDetails === LoadingStatus.Loading || userDetails.fetchingSurveillanceDetails === LoadingStatus.Loading) {
    return <div ref={loadingIndicator} tabIndex={-1} style={{ outline: 'none' }}>{t("tuberculosis.loading")}</div>
  }
  else if (userDetails.isUnderSurveillance && userDetails.fetchingSurveillanceDetails === LoadingStatus.Succeeded) {
    return <TuberculosisPageContent />
  } else {
    return <CommonInformationContent />
  }
};

export default Tuberculosis;

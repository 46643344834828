import React from "react";
import { BrowserRouter } from "react-router-dom";
import Layout from "./features/layout/layout";
import Router from "./router";
import axios, { AxiosError, AxiosResponse } from "axios";
import { INVALID_USER_KEY } from "./shared/constants/logoutConstants";
import i18n from "./locales/i18n";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { fetchMaintenanceData } from "./state/maintenance/maintenanceSlice";
import { AppDispatch } from "./state/store";

function App() {

  const dispatch = useDispatch()<any>;

  React.useEffect(() => {
    
    dispatch(fetchMaintenanceData());

    axios.interceptors.response.use(
        (response: AxiosResponse) => {
        if (response.data.status === INVALID_USER_KEY) {

            // TODO
            // const e = new Error('Invalid user type');
            // appInsights.trackException({ exception: e });

            toast.error(i18n.t('apiError.generalError'), { toastId: 'api-error' });
        }

        return response;
        }, 
        (error: AxiosError) => {
        if (error.response?.status !== 404) {
            
            // TODO
            // appInsights.trackException({ exception: error });

            toast.error(i18n.t('apiError.generalError'), { toastId: 'api-error' });
        }

        return Promise.reject(error);
        }
    );
  }, []);

  return (
    <>
      <BrowserRouter>
        <Layout>
          <ToastContainer />
          <Router />
        </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;

import { t } from "i18next";
import { createRef, useEffect } from "react";
import styles from "./commonInformationContent.module.scss";

const CommonInformationContent: React.FC = () => {
    const initialFocus = createRef<any>();
    useEffect(() => { initialFocus.current?.focus(); }, []);

    return (
        <div className="container">
            <div className="margin__top-pos--50 margin__bottom-pos--50">
                <h1 ref={initialFocus} tabIndex={0} className="form-header">{t("commonInformationContent.title")}</h1>
                <p className={styles.content}>{t('commonInformationContent.text-1')}</p>
                <p className={styles.content} dangerouslySetInnerHTML={{ __html: t('commonInformationContent.text-3') }} />
                <p className={styles.content}>{t('commonInformationContent.text-4')}</p>
            </div>
        </div>
    )
}

export default CommonInformationContent;
import React, { useState } from "react";
import inputStyles from "./checkbox.module.scss";
import commonStyles from "../../assets/styles/common.module.scss";

interface ICheckboxProps {
  text: string;
  labelClassName?: string;
  name?: string;
  isChecked?: boolean;
  enabled?: boolean;
  onBlur?: (e: any) => void;
  onChange?: (
    event: React.FormEvent<HTMLInputElement>,
    checked: boolean
  ) => any;
  classes?: string[];
}

const Checkbox: React.FC<ICheckboxProps> = ({
  text,
  labelClassName,
  name,
  isChecked,
  enabled,
  onBlur,
  onChange,
  classes,
}) => {
  const [checked, setChecked] = useState(false);
  React.useEffect(() => setChecked(!!isChecked), [isChecked]);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setChecked(!checked);

    if (onChange) {
      onChange(event, !checked); // Here was have to pass the inverted chacked value, since it was just changed in sate but the value is still old.
    }
  };

  const classNames = "editor " + (classes?.join(" ") || "");

  return (
    <div className={`${classNames} ${inputStyles.checkboxContainer}`}>
      <input
        id="checkboxInput"
        className={inputStyles.checkbox}
        tabIndex={0}
        type="checkbox"
        role="checkbox"
        name={name}
        aria-checked={checked}
        aria-labelledby="checkBoxLabel"
        checked={checked}
        onChange={handleChange}
        disabled={!enabled}
      />
      <label htmlFor="checkboxInput" className={`${commonStyles.commonMinorText} ${labelClassName}`}>{text}</label>
    </div>
  );
}

export default Checkbox;

import { User } from 'oidc-client';
import { IDP_HAG_PUOLESTA, LOGOUT_KEY } from '../shared/constants/logoutConstants';
import { ISignOutModel } from '../models/ISignOutModel';
import LoginType from '../models/LoginType';
import getServerName from './getServerName';
import userManager from './userManager';
import { getCurrentLang } from './languageUtils';

interface LoginState {
  lang: string;
  type?: LoginType;
  redirectPath?: string;
}

export const logoutHelper = async (
  path: string,
  user: User | null | undefined,
): Promise<void> => {
  if (!user)
    return Promise.resolve();

  const isOnBehalf: boolean = user?.profile?.idp === IDP_HAG_PUOLESTA;
  const state: ISignOutModel = { path, lang: getCurrentLang(), isOnBehalf };
  return userManager.signoutRedirect({
    post_logout_redirect_uri: `https://${getServerName()}/signout-oidc`,
    id_token_hint: user.id_token,
    state,
  });
};

export const loginHelper = (state: LoginState): Promise<void> => {
  const type = state.type ?? LoginType.SELF;

  if ([LoginType.ON_BEHALF, LoginType.RELOGIN_ON_BEHALF].indexOf(type) !== -1) {
    return userManager.signinRedirect({
      acr_values:'provider:suomifionbehalf',
      state,
    });
  }

  return userManager.signinRedirect({
    acr_values:'provider:suomifi',
    state,
  });
};

export const logoutCurrentUser = async () => {
  let currentUser = await userManager.getUser();
  if (currentUser !== undefined) {
    logoutHelper(LOGOUT_KEY, currentUser);
  }
}

export const renewLoginTokenCurrentUser = async () => {
  await userManager.signinSilent();
}

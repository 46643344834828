import React from 'react';
import AccessibilityReportContent from '../accessibilityReportContent/accessibilityReportContent';

const AccessibilityReportPage: React.FC = () => {
  return (
      <div className="container__row">
        <AccessibilityReportContent />
      </div>
  );
};

export default AccessibilityReportPage;

import React, { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TWithLink from '../../components/tWithLink/tWithLink';
import styles from './unauthorizedContent.module.scss';

const UnauthorizedContent: React.FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t('unauthorizedContent.title')
  }, []);

  const initialFocus = createRef<any>();
  useEffect(() => { initialFocus.current?.focus(); }, []);

  return (
    <div className="container">
      <div className="container__row">
        <div className={`${styles.contentText} container__col-12`}>
          <TWithLink
            textContainerRef={initialFocus}
            className={styles.contentTextBlock}
            text={t('unauthorizedContent.text-1')}
          />
          <TWithLink
            className={styles.contentTextBlock}
            text={t('unauthorizedContent.text-2')}
          />
          <TWithLink
            className={styles.contentTextBlock}
            text={t('unauthorizedContent.text-3')}
          />
          <TWithLink
            className={styles.contentTextBlock}
            text={t('unauthorizedContent.text-4')}
          />
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedContent;

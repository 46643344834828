import React from 'react';
import NoResultErrorContent from '../../features/personalInfoPolicyContent/personalInfoPolicyContent';

const PersonalInfoPolicyPage: React.FC = () => {
  return (
      <div className="container__row">
        <NoResultErrorContent />
      </div>
  );
};

export default PersonalInfoPolicyPage;

import React from 'react';
import AboutServiceContent from '../../features/aboutServiceContent/aboutServiceContent';

const AboutServicePage: React.FC = () => {
    return (
        <div className="container__row">
            <AboutServiceContent />
        </div>
    );
};

export default AboutServicePage;

import { configureStore } from "@reduxjs/toolkit";
import formsSlice from "./forms/formsSlice";
import settingsSlice from "./settings/settingsSlice";
import userSlice from "./userInfo/userSlice";
import maintenanceSlice from "./maintenance/maintenanceSlice";

const reducer = {
  settings: settingsSlice,
  forms: formsSlice,
  userDetails: userSlice,
  maintenances: maintenanceSlice,
}

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch

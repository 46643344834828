import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IUserDetails } from '../../models/IUserDetails';
import commonDataService from '../../services/commonDataService';
import { getSurveillanceForUser } from '../../services/surveillanceService';
import LoadingStatus from '../../models/enums/LoadingStatus';
import { SurveillanceResponse } from '../../models/SurveillanceResponse';
import { setTuberculosisInfo } from '../forms/formsSlice';
import { RootState } from '../store';


interface UserInfoState {
  fetchingUserDetails: string;
  userDetails: IUserDetails;
  hasCustomerDetails: boolean;
  fetchingSurveillanceDetails: string;
  isUnderSurveillance: boolean;
}

export const fetchUserDetailsAsync = createAsyncThunk<IUserDetails | undefined, void, { state: RootState }>(
  'userInfo/fetchUserDetails',
  async (_, thunkAPI) => {
    try {
      const res = await commonDataService.getUserData();
        return res;
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.data })
    }
  }
)

export const fetchSurveillanceDetailsAsync = createAsyncThunk<SurveillanceResponse | undefined, string>(
  'userInfo/fetchSurveillanceDetails', async (id, thunkAPI) => {
    try {
      const res = await getSurveillanceForUser(id);
      thunkAPI.dispatch(setTuberculosisInfo({ ...FormData }));
        return res;
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.data })
    }
  }
)

const initialState = {
  fetchingUserDetails: LoadingStatus.Idle,
  userDetails: {},
  hasCustomerDetails: false,
  fetchingSurveillanceDetails: LoadingStatus.Idle,
  isUnderSurveillance: false,
} as UserInfoState;

export const userSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {},
  extraReducers: (builder => {
    builder
      .addCase(fetchUserDetailsAsync.pending, (state: any, action) => {
        state.fetchingUserDetails = LoadingStatus.Loading;
      })
      .addCase(fetchUserDetailsAsync.fulfilled, (state: any, action) => {
        state.fetchingUserDetails = LoadingStatus.Succeeded;
        if (!state.hasCustomerDetails) {
          state.hasCustomerDetails = true;
          state.userDetails = action.payload;
        }
      })
      .addCase(fetchUserDetailsAsync.rejected, (state: any, action) => {
        state.fetchingUserDetails = LoadingStatus.Failed;
      })

      .addCase(fetchSurveillanceDetailsAsync.pending, (state: any, action) => {
        state.fetchingSurveillanceDetails = LoadingStatus.Loading;
      })
      .addCase(fetchSurveillanceDetailsAsync.fulfilled, (state: any, action) => {
        state.fetchingSurveillanceDetails = LoadingStatus.Succeeded;
          if (action?.payload) {
            state.isUnderSurveillance = true;
        }
      })
      .addCase(fetchSurveillanceDetailsAsync.rejected, (state: any, action) => {
        state.fetchingSurveillanceDetails = LoadingStatus.Failed;
      })
  })
})

export default userSlice.reducer
enum AlcoholUsage {
    NotSet = 0,
    Never = 1,
    LessThanOncePerMonth = 2,
    OncePerMonth = 3,
    OncePerWeek = 4,
    Daily = 5
  }
  
  export default AlcoholUsage;
  
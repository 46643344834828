import * as React from "react";
import { useTranslation } from "react-i18next";
import LoginType from "../../models/LoginType";
import { getCurrentLang, interpolationLink } from "../../utils/languageUtils";
import { loginHelper } from "../../utils/userManagerHelpers";
import commonStyles from "../../assets/styles/common.module.scss";
import styles from "./frontpageContent.module.scss";
import { langPath } from "../../utils/pathUtils";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";

const FrontpageContent: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('header.title')
  }, []);

  return (
    <div className={`container ${styles.frontPageContainer}`}>
      <div className="container__row">
        <div className="container__col-12">
          <p>{t('frontpage.content-1')}</p>
        </div>
      </div>
      <div className="container__row">
        <div className="container__col-12">
          <p dangerouslySetInnerHTML={{ __html: t('frontpage.content-2') }} />
        </div>
      </div>
      <div className="container__row">
        <div className="container__col-12">
          <p dangerouslySetInnerHTML={{ __html: t('frontpage.content-3') }} />
        </div>
      </div>
      <div className="container__row">
        <div className="container__col-12">
          <p dangerouslySetInnerHTML={{ __html: t('frontpage.content-4') }} />
        </div>
      </div>
      <div className="container__row margin__top-pos--50">
        <button className={`${commonStyles.primaryButton} ${styles.frontPageButton} container__col-md-6`} onClick={() => loginHelper({ lang: getCurrentLang(), type: LoginType.SELF })}>
          <div className={`container__row`}>
            <div className={`${styles.centeredLabel}`}>
              <label className="">{t("frontpage.selfServiceButtonLabel")}</label>
              &nbsp;
              <label className={styles.labelSubText}>{t("frontpage.selfServiceButtonLabelSubText")}</label>
            </div>
          </div>
        </button>
        <div className={`container__row`}>
          <button className={`${commonStyles.primaryButton} ${styles.frontPageButton} container__col-md-6`} onClick={() => {
            loginHelper({
              lang: getCurrentLang(),
              type: LoginType.ON_BEHALF,
            });
          }}>{t('frontpage.behalfServiceButtonLabel')}</button>
        </div>
      </div>
    </div>
  );
};

export default FrontpageContent;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoginType from '../../models/LoginType';
import { langPath, langPathWithLang } from '../../utils/pathUtils';
import userManager from '../../utils/userManager';
import { AUTO_LOGOUT_KEY } from '../../shared/constants/logoutConstants';
import { logoutHelper } from '../../utils/userManagerHelpers';
import i18n from '../../locales/i18n';
import { User } from 'oidc-client';
import { CallbackComponent } from 'redux-oidc';
import { setIsOnBehalf } from '../../state/settings/settingsSlice';

const SigninOidc: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <CallbackComponent
        userManager={userManager}
        successCallback={async (user: User) => {
          if (user?.state?.type === LoginType.SIGNOUT_ON_BEHALF) {
            return logoutHelper(AUTO_LOGOUT_KEY, user);
          }

          if (
            [LoginType.ON_BEHALF, LoginType.RELOGIN_ON_BEHALF].indexOf(
              user.state.type
            ) !== -1
          ) {
            dispatch(setIsOnBehalf(true));
          }

          i18n.changeLanguage(user.state.lang || 'fi');
          document.documentElement.setAttribute('lang', user.state.lang || 'fi');

          const redirectPath = user.state.redirectPath ?? langPath('/tuberkuloosi');
          navigate({ 
            pathname: langPathWithLang(redirectPath, user.state.lang || 'fi') },
            { state: user });
        }}
        errorCallback={(error) => { console.log(error) }}
      ><div /></CallbackComponent>
    </>
  );
};

export default SigninOidc;

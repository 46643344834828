import { ICountry } from "../models/ICountry";
import { ILanguage } from "../models/ILanguage";
import { IMunicipality } from "../models/IMunicipality";
import { getCurrentLang } from "./languageUtils";

const setLanguageVariation = (obj: ICountry | IMunicipality | ILanguage) => {
  const currentLang = getCurrentLang();
  if (currentLang === 'sv') {
    return obj.seName;
  }
  else if (currentLang === 'en') {
    return obj.enName;
  }
  else {
    return obj.fiName
  }
}

export default setLanguageVariation;

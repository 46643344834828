import React from 'react';
import ExposedTrackingForm from '../../features/exposedTrackingForm/exposedTrackingForm';

const BasicInfoPage: React.FC = () => {
  return (
      <ExposedTrackingForm />
  );
};

export default BasicInfoPage;

import React from 'react';
import styles from "./bottomNavigation.module.scss";

interface BottomNavigationProps {
  primaryButtonClick?: () => void;
  primaryButtonClass: string,
  primaryButtonLabel: string,
  primaryButtonHidden?: boolean,
  secondaryButtonClick?: () => void;
  secondaryButtonClass: string,
  secondaryButtonLabel: string,
}

const BottomNavigation: React.FC<BottomNavigationProps> = ({
  primaryButtonClick,
  primaryButtonClass,
  primaryButtonLabel,
  secondaryButtonClick,
  secondaryButtonClass,
  secondaryButtonLabel,
  primaryButtonHidden
}) => {

  return (
    <div className={`${styles.alignCenter}`}>
      <button
        type='button'
        onClick={primaryButtonClick}
        className={`${primaryButtonClass} container__col-12 container__col-sm-5 `}
        hidden={primaryButtonHidden}
      >
        {primaryButtonLabel}
      </button>
      <button
        className={`${secondaryButtonClass} container__col-12 container__col-sm-5 `}
        type='button'
        onClick={secondaryButtonClick}
      >
        {secondaryButtonLabel}
      </button>
    </div>
  );
};

BottomNavigation.defaultProps = {
  primaryButtonHidden: false,
};

export default BottomNavigation;

import axios from "axios";
import userManager from "../utils/userManager";


export const getQuestionnaireResponse = async (questionnaireIndentifier: string): Promise<any> => {
    const user = await userManager.getUser();
    if (!user) return;

    return await axios.get(`${process.env.REACT_APP_API_URL}questionnaireresponse/${questionnaireIndentifier}`, {
        headers: {
            Authorization: `${user.token_type} ${user.access_token}`,
        },
    }).then(result => result.data);
}

export const postQuestionnaireResponse = async (questionnaireIndentifier: string, data: any): Promise<any> => {
    const user = await userManager.getUser();
    if (!user) return;

    return await axios
        .put(`${process.env.REACT_APP_API_URL}questionnaireresponse/${questionnaireIndentifier}`,
            { json: JSON.stringify(data) }, {
        headers: {
            Authorization: `${user.token_type} ${user.access_token}`,
        }
    });
}


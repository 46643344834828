import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import styles from './actionBox.module.scss';
import commonStyles from '../../assets/styles/common.module.scss';

interface IActionBox {
  title: string;
  description?: string | undefined;
  note?: string;
  noteClassName?: string;
  icon?: IconDefinition | undefined;
  onClick: () => void;
  className?: string;
  iconSize?: SizeProp;
  rightContentClass?: string;
  leftContentClass?: string;
  topContentClass?: string;
  bottomContentClass?: string;
  disabledDescription?: string;
}

const ActionBox: React.FC<IActionBox> = ({
  title,
  description,
  icon,
  note,
  noteClassName,
  onClick,
  className,
  iconSize,
  rightContentClass,
  leftContentClass,
  topContentClass,
  bottomContentClass,
}) => {
  return (
    <div
      className={`${styles.actionBox} ${className}`}
    >
      <div className={`${styles.leftContent} container__row ${leftContentClass}`}>
        <div className='container__col-10'>
          <span className={styles.contentDescription}>
            {description}
          </span>
        </div>

        {icon && <div className={`${styles.iconContent} container__col-1`} aria-hidden="true">
          <FontAwesomeIcon icon={icon} size={iconSize} />
        </div>}
        <div className={`${styles.note} container__row ${styles[noteClassName || '']}`}>{note}</div>
        <div className="container__row">
          <button
            type="button"
            className={`${commonStyles.primaryButton} ${styles.primaryButton}`}
            onClick={onClick} tabIndex={0}
          >{title}</button>
        </div>
      </div>
    </div>
  );
};

ActionBox.defaultProps = {
  className: '',
  iconSize: '1x',
  rightContentClass: '',
  topContentClass: '',
  bottomContentClass: '',
};

export default ActionBox;

import * as React from "react";
import styles from "./footer.module.scss";
import husLogo from "../../assets/images/hus.svg";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { langPath } from "../../utils/pathUtils";

interface FooterProps { }

const Footer: React.FC<FooterProps> = () => {
  return (
    <>
      <hr className={styles.dividerFooter} />
      <div className={styles.footerContainer}>
        <div className="container__row">
          <div className="container__col-12">
            <div className={styles.bottomLinksContainer}>
              <span>
                <Link aria-label={`${t('footer.aboutServiceLink')} ${t('linkTooltips.newTab')}`} to={langPath('/tietoa-palvelusta')} target="_blank">
                  {t('footer.aboutServiceLink')}
                </Link>
              </span>
              <span className={styles.separator} />
              <span>
                <Link aria-label={`${t('footer.datausage')} ${t('linkTooltips.newTab')}`} to={langPath('/henkilotietojen-kasittely')} target="_blank">
                  {t('footer.datausage')}
                </Link>
              </span>
              <span className={styles.separator} />
              <span>
                <Link aria-label={`${t('footer.accessibilityReport')} ${t('linkTooltips.newTab')}`} to={langPath('/saavutettavuusseloste')} target="_blank">
                  {t('footer.accessibilityReport')}
                </Link>
              </span>

              <div className={`${styles.infectionInfo} margin__top-pos--10`}>
                <p>{t('footer.bottomInfoText')}</p>
              </div>
            </div>
          </div>
        </div>
        <img
          src={husLogo}
          alt=""
          aria-hidden={true}
        />
      </div>
    </>
  );
};

export default Footer;

import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

export const getCurrentLang = () => {
  const url = window.location.href;
  if (url.includes('/en')) {
    return 'en';
  }
  if (url.includes('/sv')) {
    return 'sv';
  }
  else return 'fi';
};

export const interpolationLink = (key:string, text:string, url: string, target?: string) => {
    return <Trans i18nKey={key}>
      a <Link target={target} to={url}>{text}</Link> p
    </Trans>
}

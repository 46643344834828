import React from 'react';
import TitleValue from '../titleValue/titleValue';
import styles from './titleValueGrid.module.scss';

interface IItem {
  title: string;
  value: string;
}

interface ITitleValueGridProps {
  label: string;
  content: IItem[];
}

const TitleValueGrid: React.FC<ITitleValueGridProps> = ({ label, content }) => (
  <div className="container">
    <div className="container__row">
      <div className={`${styles.header} container__col-12`}>
        <h2>{label}</h2>
      </div>
      {content.map((val) => (
        <div
          key={val.title}
          className={`${
            styles.itemContainer
          } container__col-12 container__col-sm-4 ${
            val.value === '' ? styles.hidden : ''
          }`}
        >
          <TitleValue title={val.title} value={val.value} />
        </div>
      ))}
    </div>
  </div>
);

export default TitleValueGrid;

import React from 'react';
import { useLocation } from 'react-router-dom';
import {getCurrentLang} from '../../utils/languageUtils';
import styles from "./languageButtons.module.scss";
import i18n from '../../locales/i18n';

interface ILanguageButtons {
}

const LanguageButtons: React.FC<ILanguageButtons> = () => {
  const location = useLocation();
  const currentLanguage = getCurrentLang();
  const languages = ['fi', 'sv', 'en'];

  const parseUrl = (lang: string) => {
    const urlParams = window.location.origin;
    const pathArr = location.pathname.split(`/${currentLanguage}`);
    const parsedPath = pathArr.join('');
    if (lang === 'fi') return urlParams+parsedPath;
    return urlParams+'/'+lang+parsedPath;
  }

  const onChange = (lang: string) => {
    i18n.changeLanguage(lang || 'fi');
    document.documentElement.setAttribute('lang', lang || 'fi');
  }

  return (
    <div className={styles.headerLanguage + " container__col-12"}>
      {languages
        .filter((x) => x !== currentLanguage)
        .map((lang, i) => (
          <a
            onClick={() => onChange(lang)}
            key={lang}
            href={parseUrl(lang)}
            className={styles.languageSelectors}
          >
            {i === 0 ? `${lang.toUpperCase()} | ` : lang.toUpperCase()}
          </a>
        ))}
    </div>
  );
};

export default LanguageButtons;

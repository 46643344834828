import React, { useState } from 'react';
import Select, { AriaOnFilterProps } from 'react-select';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import styles from './dropdown.module.scss';

interface IOption {
  value: string;
  label: string;
}

interface IDropdownProps {
  name: string;
  label: string;
  options: IOption[];
  required?: boolean;
  labelClass?: string;
  placeholder?: string;
  className?: string;
  classNamePrefix?: string;
  onChange?: (val: IOption) => void;
  labelTextClass?: string;
  errorMessageClass?: string;
  labelWrapperClass?: string;
  value?: IOption;
  customHeight?: number;
  inputClass?: string;
  disable?: boolean;
}

const Dropdown: React.FC<IDropdownProps> = ({
  name,
  label,
  options,
  required,
  labelClass,
  placeholder,
  className,
  classNamePrefix,
  labelTextClass,
  errorMessageClass,
  labelWrapperClass,
  inputClass,
  onChange,
  value,
  customHeight,
  disable = false,
}) => {
  const [field, meta, form] = useField(name);
  const { t } = useTranslation();
  const [ariaFocusMessage, setAriaFocusMessage] = useState('');
  const [ariaFilterMessage, setAriaFilterMessage] = useState('');

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: customHeight,
      minHeight: customHeight,
    })
  };

  const onFocus: any = (focused: any, isDisabled: any) => {
    const msg = `${focused.label}`;
    setAriaFocusMessage(msg);
    return msg;
  };

  const onFilter: any = (props: AriaOnFilterProps) => {
    const msg = ``;
    setAriaFilterMessage(msg);
    return msg;
  };

  return (
    <div className={`${styles.dropdownWrapper} ${className}`}>
      <div className={labelWrapperClass}>
        {required && <span className={styles.asterisk} aria-hidden>*</span>}
        <label
          htmlFor={name}
          className={`${styles.label} ${
            required ? styles.required : ''
          } ${labelClass}`}
        >
          <span className={labelTextClass}>{label}</span>
        </label>
      </div>
      <Select
        name={name}
        options={options}
        onChange={(val: IOption) => {
          if (onChange) onChange(val);
        }}
        styles={customStyles}
        onBlur={field.onBlur}
        value={value || field.value}
        className={`${styles.select} ${inputClass}`}
        placeholder={placeholder}
        classNamePrefix={classNamePrefix}
        inputId={name}
        aria-labelledby={meta.error ? `${name}-error` : ``} // HACK: Use error message as aria label, because aria-describedby is not supported: https://github.com/JedWatson/react-select/issues/1570
        ariaLiveMessages={{
          guidance: () => '',
          onChange: (props) => `${props.label} ${t('formValidations.selected')}`,
          onFocus,
          onFilter
        }}
        isDisabled={disable}
      />
      <p id={`${name}-error`} className={`${styles.errorMsg} ${errorMessageClass}`}>{meta.error}</p>
    </div>
  );
};

Dropdown.defaultProps = {
  required: false,
  labelClass: '',
  classNamePrefix: '',
  placeholder: '',
  className: '',
  onChange: undefined,
  labelTextClass: '',
  labelWrapperClass: '',
  value: undefined,
  customHeight: 38,
  errorMessageClass: '',
  inputClass: '',
};

export default Dropdown;

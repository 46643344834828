import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const addDays = (date: Date | string, days: number) => {
  if (typeof date == "string") {
    date = parseDate(date);
  }

  const millisToAdd = days * 24 * 60 * 60 * 1000;
  const newDate = new Date(date.getTime() + millisToAdd);

  return newDate;
};

const parseDate = (value: string) => {
  const pattern = /(\d{1,2})\.(\d{1,2})\.(\d{4})/;
  if (!pattern.test(value))
    throw new Error(`Unable to parse date! Expected dd.mm.yyyy, got ${value}`);

  const results = value.match(pattern)!.map((x) => parseInt(x));
  const date = new Date(results[3], results[2] - 1, results[1]);

  return date;
};

/**
 * Converts the Date to simple date string
 * @returns DD.MM.YYYY
 * @example 24.12.2020
 * @example 1.1.2020
 */
const convertToSimpleDateString = (date: Date) => {
  return (
    date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
  );
};

/**
 * Converts the Date to simple time string in 24h format, with leading zeros
 * @returns HH:MM
 * @example 15:00
 * @example 05:00
 */
const convertToSimpleTimeString = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const hoursPrefix = hours < 10 ? "0" : "";
  const minutesPrefix = minutes < 10 ? "0" : "";
  return `${hoursPrefix}${hours}:${minutesPrefix}${minutes}`;
};

/**
 * Converts the Date to full string represantion
 * @example 24.12.2020 15:00
 * @example 1.1.2020 05:00
 */
const convertToFullDateTimeString = (date: Date) => {
  const dateString = convertToSimpleDateString(date);
  const timeString = convertToSimpleTimeString(date);
  return `${dateString} ${timeString}`;
};

const timeBetween = (date1: Date, date2: Date) => {
  let span = Math.abs(date1.getTime() - date2.getTime()) / 1000;
  var hours = Math.floor(span / 3600) % 24;
  let mins = Math.floor(span / 60) % 60;
  let secs = Math.round(span % 60);
  return (
    (hours > 0 ? `${hours}:` : "") +
    (mins < 10 ? `0${mins}` : mins) +
    ":" +
    (secs < 10 ? "0" : "") +
    secs
  );
};

const convertStringToDate = (value: any) => {
  const m = dayjs.utc(value, "DD.MM.YYYY");
  return m.isValid() ? m.toDate() : undefined;
};

const convertToString = (value?: any) => {
  const date = value && new Date(Date.parse(value));
  return (
    (date &&
      `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`) ||
    ""
  );
};

const parseDateAndtimeStringsToDateObject = (
  dateString: string,
  timeString: string
) => {
  try {
    const date = parseDate(dateString);
    const timeParts = timeString && timeString.split(":");

    if (timeParts.length !== 2) {
      return date;
    }

    date.setHours(+timeParts[0]);
    date.setMinutes(+timeParts[1]);

    return date;
  } catch {
    return undefined;
  }
};

/**
 * Parses all properties with name suffix At to JS Date objects.
 * @returns Shallow copy of the object with date properties parsed as date objects
 */
const parseObjectDatestamps: (input: { [key: string]: any }) => {
  [key: string]: any;
} = (input) => {
  const retObj = { ...input };

  Object.keys(input).forEach((x) => {
    if (x.endsWith("At")) {
      retObj[x] = !!input[x] ? new Date(input[x]) : undefined;
    }
  });

  return retObj;
};

/**
 * Calculate the age from SSN
 * @param ssn SSN to calculate the age from
 */
export function calculateAge(ssn: string): number | null {
  try {
    if (ssn.length >= 6 && ssn.length <= 11) {
      var day = ssn.slice(0, 2);
      if (!isNumber(day)) {
        return null;
      }
      var month = ssn.slice(2, 4);
      if (!isNumber(month)) {
        return null;
      }
      var yearSymbol = ssn[6];
      if (!isValidYearSymbol(yearSymbol)) {
        return null;
      }

      var year = "";
      if (validCenturyCharsFor19thCentury.some((x) => x === yearSymbol)) {
        year = "18";
      } else if (
        validCenturyCharsFor20thCentury.some((x) => x === yearSymbol)
      ) {
        year = "19";
      } else if (
        validCenturyCharsForBornInOrAfter2000.some((x) => x === yearSymbol)
      ) {
        year = "20";
      }
      year = year + ssn.slice(4, 6);
      if (!isNumber(year)) {
        return null;
      }

      var age = dayjs().diff(
        dayjs(`${day}.${month}.${year}`, "DD.MM.YYYY"),
        "years"
      );
      if (isNaN(age) || Number(age) < 0) {
        return null;
      }
      return age;
    }
  } catch {}

  return null;
}

// For those born in the 19th century, the current plus sign(+) – no new intermediate characters will be introduced for such cases.
const validCenturyCharsFor19thCentury = ["+"];

// For those born in the 20th century, the current hyphen(-) or the new letters Y, X, W, V, U.
const validCenturyCharsFor20thCentury = ["-", "Y", "X", "W", "V", "U"];

// For those born in or after 2000, the current A or the new B, C, D, E, F.
const validCenturyCharsForBornInOrAfter2000 = ["A", "B", "C", "D", "E", "F"];

const validCenturyChars = validCenturyCharsFor19thCentury
  .concat(validCenturyCharsFor20thCentury)
  .concat(validCenturyCharsForBornInOrAfter2000);

function isValidYearSymbol(yearSymbol: string): boolean {
  if (validCenturyChars.some((x) => x === yearSymbol)) {
    return true;
  }

  return false;
}

function isNumber(number: string): boolean {
  if (isNaN(Number(number))) {
    return false;
  }

  return true;
}

function formattedTimeFromSeconds(timeLeft: number): string {
  var hours = "0" + Math.floor(timeLeft / 3600);
  var minutes = "0" + Math.floor((timeLeft % 3600) / 60);
  var seconds = "0" + Math.floor((timeLeft % 3600) % 60);
  var formattedTime =
    hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
  return formattedTime;
}

//// Use when you need to show API returned datetime data on UI.
//// This negates the effect if client has some weird timezone instead of Finnish +2/+3 timezone
//export function showInFiTimezone(
//  value: dayjs.Dayjs | null
//): dayjs.Dayjs | null {
//  const finnishValue = value?.clone().tz("Europe/Helsinki");
//  return finnishValue
//    ? dayjs([finnishValue.year(), finnishValue.month(), finnishValue.date()])
//    : null;
//}

//// Use when you need to send datetime value to API
//// This makes datetime picked to force that it is picked as in Finnish timezone
//export function saveDateFromFIUtcValue(
//  value: dayjs.Dayjs | null | undefined
//): dayjs.Dayjs | null {
//  const finnnishValue = value
//    ? dayjs({
//        year: value.year(),
//        month: value.month(),
//        date: value.date(),
//        hours: value.hours(),
//        minutes: value.minutes(),
//        seconds: value.seconds()
//      }).tz("Europe/Helsinki")
//    : undefined;
//  return finnnishValue?.utc() ?? null;
//}

export default {
  addDays,
  parseDate,
  convertToSimpleDateString,
  convertToSimpleTimeString,
  convertToFullDateTimeString,
  parseDateAndtimeStringsToDateObject,
  timeBetween,
  parseObjectDatestamps,
  convertStringToDate,
  convertToString,
  formattedTimeFromSeconds,
};

import React from 'react';
import { useSelector } from 'react-redux';
import FormType from '../../models/FormType';
import FormBreadcrumbs from '../formBreadcrumbs/formBreadcrumbs';
import PersonalInformationForm from '../personalInformationForm/personalInformationForm';
import GeneralMedicalInformationForm from '../generalMedicalInfoForm/generalMedicalInfoForm';
import SymptomsInformationForm from '../symptomsInformationForm/symptomsInformationForm';
import SummaryAndSendingPage from '../summaryAndSending/summaryAndSending';
import { RootState } from "../../state/store";
import { Navigate } from 'react-router-dom';
import { langPath } from '../../utils/pathUtils';
import LoadingStatus from '../../models/enums/LoadingStatus';


const ExposedTrackingForm: React.FC = () => {
  const formType = useSelector((state: RootState) => state.forms.formType);
  const published = useSelector((state: RootState) => state.forms.tuberculosisForm.published);
  const status = useSelector((state: RootState) => state.forms.status);
  const formDataLoaded = useSelector((state: RootState) => state.forms.formDataLoaded);
  const userDetails = useSelector((state: RootState) => state.userDetails);

  if (published) {
    return (<SummaryAndSendingPage />)
  }

  if (!userDetails.isUnderSurveillance) {
    return <Navigate to={langPath('/')} />
  }

  if (status === LoadingStatus.Idle && formDataLoaded && userDetails.isUnderSurveillance) {
    return (
      <>
        <FormBreadcrumbs />
        {formType === FormType.PERSONAL_INFO ? <PersonalInformationForm /> : null}
        {formType === FormType.GENERAL_MEDICAL_INFO ? <GeneralMedicalInformationForm /> : null}
        {formType === FormType.SYMPTOMS ? <SymptomsInformationForm /> : null}
        {formType === FormType.READY ? <SummaryAndSendingPage /> : null}
      </>
    );
  }

  return(<div></div>)

};

export default ExposedTrackingForm;

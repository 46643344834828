import React from 'react';
import PropTypes from 'prop-types';
import styles from './titleValue.module.scss';

interface ITitleValueProps {
  title: string;
  value: string;
  titleClass?: string;
  valueClass?: string;
  containerClass?: string;
}

const TitleValue: React.FC<ITitleValueProps> = ({
  title,
  value,
  titleClass,
  valueClass,
  containerClass,
}) => {
  return (
    <div className={`${styles.container} container ${containerClass}`}>
      <div
        className={`${styles.titleWrapper} container container__row ${titleClass}`}
      >
        {title}
      </div>
      <div
        className={`${styles.valueWrapper} container container__row ${valueClass}`}
      >
        {value}
      </div>
    </div>
  );
};

TitleValue.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  titleClass: PropTypes.string,
  valueClass: PropTypes.string,
  containerClass: PropTypes.string,
};

TitleValue.defaultProps = {
  titleClass: '',
  valueClass: '',
  containerClass: '',
};

export default TitleValue;

import { t } from "i18next";
import { createRef, useEffect } from "react";
import TWithLink from "../../components/tWithLink/tWithLink";
import { langPath } from "../../utils/pathUtils";

const AboutServiceContent: React.FC = () => {
    const initialFocus = createRef<any>();
    useEffect(() => { initialFocus.current?.focus(); }, []);

  return (
         <div className="container">
            <div className="container__row margin__bottom-pos--18">
                <h1 ref={initialFocus} tabIndex={0} className="form-header">{t('aboutServiceContent.title')}</h1>
            </div>
            <div className="container__row margin__bottom-pos--18">
                <p>{t('aboutServiceContent.updated')}</p>
            </div>
            <div className="container__row">
                <p className="form-header__sub--small-bold">{t('aboutServiceContent.subtitle-1')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-1')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <TWithLink
                    text={t('aboutServiceContent.content-1-2')}
                />
            </div>
            <div className="container__row">
                <p>{t('aboutServiceContent.content-1-3')}</p>
            </div>

            <div className="container__row">
                <p className="form-header__sub--small-bold">{t('aboutServiceContent.subtitle-2')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-2')}</p>
            </div>
            <div className="container__row">
                <p>{t('aboutServiceContent.content-2-1')}</p>
            </div>
            <div className="container__row">
                <p className="form-header__sub--small-bold">{t('aboutServiceContent.subtitle-3')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-3')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-3-1')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-3-2')}</p>
            </div>
            <div className="container__row">
                <p className="form-header__sub--small-bold">{t('aboutServiceContent.subtitle-4')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-4')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-4-1')}</p>
            </div>
            <div className="container__row">
                <p className="form-header__sub--small-bold">{t('aboutServiceContent.subtitle-5')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-5')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-5-1')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-5-2')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-5-3')}</p>
                <a href={langPath('/henkilotietojen-kasittely')}>{t('footer.datausage')}</a>
            </div>
            <div className="container__row">
                <p className="form-header__sub--small-bold">{t('aboutServiceContent.subtitle-6')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-6')}</p>
            </div>
            <div className="container__row">
                <p className="form-header__sub--small-bold">{t('aboutServiceContent.subtitle-7')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-7')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
              <TWithLink
                text={t('aboutServiceContent.content-7-1')}
              />
            </div>
            <div className="container__row margin__bottom-pos--15">
                <p>{t('aboutServiceContent.content-7-2')}</p>
            </div>
            <div className="container__row margin__bottom-pos--15">
              <TWithLink
                text={t('aboutServiceContent.content-7-3')}
              />
            </div>
            <div className="container__row">
                <p className="form-header__sub--small-bold">{t('aboutServiceContent.subtitle-8')}</p>
            </div>
            <div className="container__row margin__bottom-pos--60">
                <p>{t('aboutServiceContent.content-8')}</p>
            &nbsp;<a href={langPath('/saavutettavuusseloste')}>{t('footer.accessibilityReport')}</a>
            </div>
        </div>
    )
}

export default AboutServiceContent;

import * as React from "react";
import { Route, Routes } from "react-router-dom";
import IsConsentRoute from "./guards/isConsent";
import Frontpage from "../pages/frontpage";
import SigninOidc from "../features/signinOidc/signinOidc";
import SingoutOidc from "../features/signoutOidc/signoutOidc";
import { Navigate } from 'react-router-dom';
import OnBehalfErrorPage from "../pages/onBehalfErrorPage";
import PageNotFound from "../features/pageNotFound/pageNotFound";
import BasicInfoPage from "../pages/basicInfoPage";
import Unauthorized from "../pages/unauthorized";
import PersonalInfoPolicyPage from "../pages/personalInfoPolicyPage";
import AccessibilityReportPage from "../features/accessibilityReport";
import Tuberculosis from "../pages/tuberculosis";
import AboutServicePage from "../pages/aboutServicePage";
import IsMaintenanceRoute from "./guards/isMaintenance";

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<IsMaintenanceRoute />}>
        <Route path="/signout-oidc" element={<SingoutOidc />} />
        <Route path="/signin-oidc" element={<SigninOidc />} />
        <Route path="/" element={<Frontpage />} />
        <Route path="/sv/" element={<Frontpage />} />
        <Route path="/en/" element={<Frontpage />} />
        <Route path="/sv/*" element={<Navigate to={'/sv/'} />} />
        <Route path="/en/*" element={<Navigate to={'/en/'} />} />
        <Route path="/puolesta-asiointi-virhe" element={<OnBehalfErrorPage />} />
        <Route path="/sv/puolesta-asiointi-virhe" element={<OnBehalfErrorPage />} />
        <Route path="/en/puolesta-asiointi-virhe" element={<OnBehalfErrorPage />} />
        <Route path="/virheellinen-kayttaja" element={<Unauthorized />} />
        <Route path="/sv/virheellinen-kayttaja" element={<Unauthorized />} />
        <Route path="/en/virheellinen-kayttaja" element={<Unauthorized />} />
        <Route path="/henkilotietojen-kasittely" element={<PersonalInfoPolicyPage />} />
        <Route path="/sv/henkilotietojen-kasittely" element={<PersonalInfoPolicyPage />} />
        <Route path="/en/henkilotietojen-kasittely" element={<PersonalInfoPolicyPage />} />
        <Route path="/tietoa-palvelusta" element={<AboutServicePage />} />
        <Route path="/sv/tietoa-palvelusta" element={<AboutServicePage />} />
        <Route path="/en/tietoa-palvelusta" element={<AboutServicePage />} />
        <Route path="/saavutettavuusseloste" element={<AccessibilityReportPage />} />
        <Route path="/sv/saavutettavuusseloste" element={<AccessibilityReportPage />} />
        <Route path="/en/saavutettavuusseloste" element={<AccessibilityReportPage />} />
        <Route element={<IsConsentRoute />}>
          <Route path="tuberkuloosi" element={<Tuberculosis />} />
          <Route path="sv/tuberkuloosi" element={<Tuberculosis />} />
          <Route path="en/tuberkuloosi" element={<Tuberculosis />} />
          <Route path="henkilotiedot" element={<BasicInfoPage />} />
          <Route path="sv/henkilotiedot" element={<BasicInfoPage />} />
          <Route path="en/henkilotiedot" element={<BasicInfoPage />} />
          <Route path="sv/*" element={<PageNotFound />} />
          <Route path="en/*" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="" element={<Navigate to={'/tuberkuloosi'} />} />
          <Route path="sv/" element={<Navigate to={'/sv/tuberkuloosi'} />} />
          <Route path="en/" element={<Navigate to={'/en/tuberkuloosi'} />} />
        </Route>
      </Route>
    </Routes>
  );
};


export default Router;

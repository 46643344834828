import React, { createRef, useEffect, useState } from 'react';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IOption } from '../../models/IOption';
import { TuberculosisForm } from '../../models/TuberculosisForm';
import { Formik, Form } from 'formik';
import Dropdown from '../../components/dropdown/dropdown';
import { langPath } from '../../utils/pathUtils';
import { useNavigate } from 'react-router-dom';
import FormType from '../../models/FormType';
import { RootState } from "../../state/store";
import { setFormType, setTuberculosisInfo } from '../../state/forms/formsSlice';
import { postQuestionnaireResponse } from '../../services/questionnaireService';
import dayjs from 'dayjs';
import ValidationErrorMsg from '../../components/validationErrorMsg/validationErrorMsg';
import TitleValueGrid from '../../components/titleValueGrid/titleValueGrid';
import BottomNavigation from '../../components/bottomNavigation/bottomNavigation';
import AlcoholUsage from '../../models/enums/AlcoholUsage';
import YesNoUnknown from '../../models/YesNoUnknown';
import { calculateAge } from '../../utils/dateUtils';
import DatePicker from '@husepidemic/epidemicdesign/datePicker';
import { ConnectedFocusError } from 'focus-formik-error';
import commonStyles from '../../assets/styles/common.module.scss';
import FourOptions from '../../models/fourOptions';
import NewRadioGroup from '../../components/newRadioGroup/newRadioGroup';
import Checkbox from '../../components/checkbox/checkbox';
import InputWithTitle from '@husepidemic/epidemicdesign/inputWithTitle';
import TooltipModal from '../../components/tooltipModal/tooltipModal';
import yearOptions from '../../utils/yearOptions';

const PersonalInformationForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formData = useSelector((state: RootState) => state.forms.tuberculosisForm);
  const { municipalities, countries, languages } = useSelector((state: RootState) => state.forms);
  const userData = useSelector((state: RootState) => state.userDetails);
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(true);
  const [sortedMunicipalities, setSortedMunicipalities] = useState<IOption[] | undefined>();
  const [municipalityEquals, setMunicipalityEquals] = useState(
    userData.userDetails?.address?.postalAddress === formData.streetAddress && userData.userDetails?.address?.locality === formData.residenceMunicipality?.label);
  const [sortedCountries, setSortedCountries] = useState<IOption[] | undefined>();
  const [sortedLanguages, setSortedLanguages] = useState<IOption[] | undefined>();
  const initialFocus = createRef<any>();
  useEffect(() => { initialFocus.current?.focus(); }, []);

  const userAge = calculateAge(userData.userDetails.maskedSsn !== undefined && userData.userDetails.maskedSsn !== null ? userData.userDetails.maskedSsn : '');
  const isChild = userAge !== null && userAge <= 18 ? true : false;
  const isYoungChild = userAge !== null && userAge < 8 ? true : false;
  const finlandCountryId = '246';
  useEffect(() => {
    document.title = t('personalInformationForm.title')
  }, []);

  useEffect(() => {
    let muni: IOption[] = [{ label: t('personalInformationForm.residenceMunicipalityNoSelection'), value: '' }].concat(municipalities);
    muni.sort((a, b) => {
      if (a.value.startsWith('200')) { return 1; }
      if (b.value.startsWith('200')) { return -1; }
      return a.value.localeCompare(b.value)
    });
    muni.sort((a, b) => {
      if (a.value.startsWith('200')) { return 1; }
      if (b.value.startsWith('199')) { return -1; }
      return a.value.localeCompare(b.value)
    });
    setSortedMunicipalities(muni);
  }, [municipalities]);

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required(t('formValidations.required-field-phoneNumber'))
      .matches(
        /^(?:[+]?\s*\d){10,15}$/,
        t('formValidations.phone-validation')
      ),
    streetAddress: Yup.string()
      .min(5, t('formValidations.required-field-streetAddressMinLenght'))
      .when('residenceMunicipality', {
        is: (residenceMunicipality: any) => residenceMunicipality && (residenceMunicipality?.label !== userData.userDetails.address?.locality),
        then: Yup.string()
          .required(t('formValidations.required-field-postalCode')),
      }),
    postalCode: Yup.string()
      .matches(/^([0-9]+)$/, {
        message: t('formValidations.no-match-value-number'),
        excludeEmptyString: true,
      })
      .min(5, t('formValidations.postalCode-value-too-short'))
      .max(5, t('formValidations.postalCode-value-too-long'))
      .when('residenceMunicipality', {
        is: (residenceMunicipality: any) => residenceMunicipality && (residenceMunicipality?.label !== userData.userDetails.address?.locality),
        then: Yup.string()
          .required(t('formValidations.required-field-postalCode')),
      }),
    birthCountry: Yup.object()
      .required(t('formValidations.required-field-birthCountry')),
    immigrationDate: Yup.string().when("birthCountry", {
      is: (x: any | undefined) => x?.value !== countries?.find(e => e.value === finlandCountryId)?.value && x !== undefined,
      then: Yup.string().required(t('formValidations.required-field-immigrationDate')).nullable().transform(x => x === '' ? null : x),
    }).nullable().transform(x => x === '' ? null : x),
    occupation: Yup.string().when([], {
      is: () => !isChild,
      then: Yup.string()
        .min(5, t('formValidations.occupation-value-too-short'))
        .max(50, t('formValidations.occupation-value-too-long'))
        .required(t('formValidations.required-field-occupation')),
      otherwise: Yup.string()
        .min(5, t('formValidations.occupation-value-too-short'))
        .max(50, t('formValidations.occupation-value-too-long'))
        .notRequired(),
    }),
    placeOfWorkOrStudy: Yup.string().when([], {
      is: () => !isChild,
      then: Yup.string()
        .min(5, t('formValidations.placeOfWorkOrStudy-value-too-short'))
        .max(50, t('formValidations.placeOfWorkOrStudy-value-too-long'))
        .required(t('formValidations.required-field-placeOfWorkOrStudy')),
      otherwise: Yup.string()
        .min(5, t('formValidations.placeOfWorkOrStudy-value-too-short'))
        .max(50, t('formValidations.placeOfWorkOrStudy-value-too-long'))
        .notRequired(),
    }),
    height: Yup.number(),
    weight: Yup.number(),
    alcoholUsage: Yup.number().required(t('formValidations.required-field-alcoholUsage'))
      .oneOf(Object.values(AlcoholUsage) as [], t('formValidations.required-field-alcoholUsage')),
    waterpipeUsage: Yup.boolean()
      .required(t('formValidations.required-field-waterpipeUsage')),
    drugUsage: Yup.boolean()
      .required(t('formValidations.required-field-drugUsage')),
    cannabisUsage: Yup.string().when("drugUsage", {
      is: true,
      then: Yup.string()
        .required(t('formValidations.required-field-cannabisUsage')),
    }),
    intravenously: Yup.string().when("drugUsage", {
      is: true,
      then: Yup.string()
        .required(t('formValidations.required-field-intravenously')),
    }),
    pregnant: Yup.string()
      .required(t('formValidations.required-field-pregnant'))
  });

  const handleSubmit = async (values: TuberculosisForm) => {
    setIsValid(true);
    await saveFormData(values);
    dispatch(setFormType(FormType.GENERAL_MEDICAL_INFO));
  }

  const saveFormData = async (values: TuberculosisForm) => {
    const saveData = {...values};
    const municipality = sortedMunicipalities?.find(x => x.label === userData.userDetails?.address?.locality);
    saveData.domicileMunicipalityId = municipality?.value;
    saveData.domicileStreetAddress = userData.userDetails?.address?.postalAddress;
    saveData.domicilePostalCode = userData.userDetails?.address?.postalCode;
    await postQuestionnaireResponse("tuberculosis", saveData)
      .then(() => {
        dispatch(setTuberculosisInfo(saveData));
      });
  }

  const handleCheck = (setFieldValue: (field: string, value: any) => void) => {
    setMunicipalityEquals(!municipalityEquals)
    if(!municipalityEquals && userData.userDetails?.address !== null) {
      const municipality = sortedMunicipalities?.find(x => x.label === userData.userDetails?.address?.locality);
      setFieldValue("postalCode", userData.userDetails?.address?.postalCode);
      setFieldValue("streetAddress", userData.userDetails?.address?.postalAddress);
      setFieldValue('residenceMunicipalityId', municipality?.value);
      setFieldValue('residenceMunicipality', municipality)
    } else {
      setFieldValue("postalCode", '');
      setFieldValue("streetAddress", '');
      setFieldValue('residenceMunicipalityId', null);
      setFieldValue('residenceMunicipality', '')
    }
  }

  const [showPhoneNumberInfo, setShowPhoneNumberInfo] = useState(false);
  const [showContactPhoneNumberInfo, setShowContactPhoneNumberInfo] = useState(false);
  const [focusOnModal, setFocusOnModal] = useState(false);
  const [focusOnQuestion, setFocusOnQuestion] = useState('');

  useEffect(() => {
    setFocusOnModal(!focusOnModal);
    if (focusOnQuestion === 'phoneNumber') { document.getElementById('phoneNumberLabelId')?.focus(); setFocusOnQuestion(''); };
    if (focusOnQuestion === 'contactPhoneNumber') { document.getElementById('contactPhoneNumberLabelId')?.focus(); setFocusOnQuestion(''); };

  }, [showPhoneNumberInfo, showContactPhoneNumberInfo])

  useEffect(() => {
    const countriesSorted: IOption[] = [...countries].sort((a, b) => a.label.localeCompare(b.label));
    setSortedCountries(countriesSorted);
  }, [countries])

  useEffect(() => {
    const languagesSorted: IOption[] = [...languages].sort((a, b) => a.label.localeCompare(b.label));
    setSortedLanguages(languagesSorted);
  }, [languages])

  return (
    <Formik
      initialValues={formData}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values: TuberculosisForm) => {
        await handleSubmit(values);
      }}
    >
      {({ values, setFieldValue, validateForm, handleSubmit }) => (
        <Form noValidate className='container'>
          <ConnectedFocusError />
          <h1 ref={initialFocus} tabIndex={-1} className="form-header">{t('personalInformationForm.title')}</h1>
          <p className="form-header__sub">{t('personalInformationForm.description')}</p>
          <TitleValueGrid
            label={t('personalInformationForm.personalInformation')}
            content={[
              {
                title: t('properties.name'),
                value: `${userData.userDetails?.firstName?.toUpperCase()} ${userData.userDetails?.lastName?.toUpperCase()}`,
              },
              {
                title: t('properties.ssn'),
                value: userData.userDetails?.maskedSsn ?? '',
              },
            ]}
          />
          <TitleValueGrid
            label={t('personalInformationForm.contactInformation')}
            content={[]}
          />
          <div className="container__row">
            <p aria-hidden="true">{t('properties.required')}</p>
          </div>
          <TitleValueGrid
            label={""}
            content={[
              {
                title: t('properties.homeMunicipality'),
                value: userData.userDetails?.address?.locality?.toUpperCase() ?? '',
              },
              {
                title: t('properties.streetAddress'),
                value: userData.userDetails?.address?.postalAddress?.toUpperCase() ?? '',
              },
              {
                title: t('properties.postalCode'),
                value: userData.userDetails?.address?.postalCode?.toUpperCase() ?? '',
              }
            ]}
          />
          <div className="container__row">
            <Checkbox
              classes={['margin__top-pos--17 margin__bottom-pos--5']}
              text={t('personalInformationForm.isSameAddress')}
              isChecked={municipalityEquals}
              enabled={true}
              onChange={() => handleCheck(setFieldValue)}
            />
          </div>
          {!municipalityEquals?<div className="container__row">
            <Dropdown
              className='container__col-12 container__col-sm-5'
              options={sortedMunicipalities !== undefined ? sortedMunicipalities : [{ label: (t('personalInformationForm.residenceMunicipalityListError')), value: '' }]}
              name='residenceMunicipality'
              onChange={
                (value: IOption) => {
                  setFieldValue('residenceMunicipality', value)
                  setFieldValue('residenceMunicipalityId', value.value)
                }}
              label={t("personalInformationForm.residenceMunicipality")}
              labelTextClass="form__label"
              inputClass='form__label--input'
            />
          </div>:null}
          {!municipalityEquals?<div className="container__row">
            <InputWithTitle
              name='streetAddress'
              labelClassName="form__label"
              className='container__col-12 container__col-sm-5 height__min--85 margin__right-pos--5-mobile'
              inputClassName="form__label--input"
              label={t("personalInformationForm.streetAddress")}
              required={(userData.userDetails?.address?.locality !== values.residenceMunicipality?.label) && values?.residenceMunicipality?.label !== undefined}
              errorClassName="form__error"
              maxLength={500}
            />
            <InputWithTitle
              name='postalCode'
              labelClassName="form__label"
              className="container__col-12 container__col-sm-5 height__min--85"
              label={t("personalInformationForm.postalCode")}
              inputClassName="form__label--input"
              required={(userData.userDetails?.address?.locality !== values.residenceMunicipality?.label) && values?.residenceMunicipality?.label !== undefined}
              errorClassName="form__error"
              maxLength={10}
            />
          </div>:null}
          <div className="container__row">
            <InputWithTitle
              name='phoneNumber'
              labelClassName="form__label"
              className='container__col-12 container__col-sm-5 height__min--85'
              label={t("personalInformationForm.phoneNumber")}
              required={true}
              inputClassName="form__label--input"
              errorClassName="form__error"
              maxLength={15}
              iconClick={() => setShowPhoneNumberInfo(true)}
              infoButtonLabel={t("accessibility.infoButtonLabel")}
            />
            <TooltipModal
              show={showPhoneNumberInfo}
              tooltipHeader={t('personalInformationForm.phoneNumber')}
              tooltipText={t('personalInformationForm.phoneNumberTooltipInfo')}
              closeButtonText={t('common.close')}
              onCloseButtonClick={() => { setShowPhoneNumberInfo(false); setFocusOnQuestion('phoneNumber') }}
              trapModalItems="h2, p, button:not([disabled])"
            />
          </div>
          <div className="container__row">
            <Dropdown
              options={sortedLanguages !== undefined ? sortedLanguages : [{ label: (t('personalInformationForm.languageListError')), value: '' }]}
              name='language'
              required={false}
              className='container__col-12 container__col-sm-5'
              value={languages.find(x => x.value===values.communicationLanguage)}
              onChange={
                (value: IOption) => {
                  setFieldValue('communicationLanguage', value.value);
                }}
              label={t("personalInformationForm.language")}
              labelTextClass="form__label"
              inputClass='form__label--input'
            />
          </div>
          <div className="container__row">
            <InputWithTitle
              name='contactedBy'
              required={false}
              className='container__col-12 container__col-sm-5 height__min--85 margin__right-pos--5-mobile'
              label={t("personalInformationForm.contactedBy")}
              labelClassName="form__label"
              inputClassName="form__label--input"
              errorClassName="form__error"
              maxLength={50}
            />
            <InputWithTitle
              name='contactPhoneNumber'
              required={false}
              className="container__col-12 container__col-sm-5 height__min--85"
              label={t("personalInformationForm.contactPhoneNumber")}
              labelWrapperClassName={`${commonStyles.text__nowrap}`}
              labelClassName="form__label"
              inputClassName="form__label--input"
              errorClassName="form__error"
              maxLength={50}
              iconClick={() => setShowContactPhoneNumberInfo(true)}
              infoButtonLabel={t("accessibility.infoButtonLabel")}
            />
            <TooltipModal
              show={showContactPhoneNumberInfo}
              tooltipHeader={t('personalInformationForm.contactPhoneNumber')}
              tooltipText={t('personalInformationForm.contactPhoneNumberTooltipInfo')}
              closeButtonText={t('common.close')}
              onCloseButtonClick={() => { setShowContactPhoneNumberInfo(false); setFocusOnQuestion('contactPhoneNumber') }}
              trapModalItems="h2, p, button:not([disabled])"
            />
          </div>
          <div className="container__row">
            <Dropdown
              options={sortedCountries !== undefined ? sortedCountries : [{ label: (t('personalInformationForm.birthCountryListError')), value: '' }]}
              name='birthCountry'
              required
              className='container__col-12 container__col-sm-5'
              onChange={
                (value: IOption) => {
                  setFieldValue('birthCountryId', value.value);
                  setFieldValue('birthCountry', value);
                }}
              label={t("personalInformationForm.birthCountry")}
              labelTextClass="form__label"
              inputClass='form__label--input'
            />
            {values.birthCountryId !== finlandCountryId && values.birthCountryId !== undefined ?
              <DatePicker
                name='immigrationDate'
                errorClass={commonStyles.errorMsg}
                required={values.birthCountryId !== finlandCountryId && values.birthCountryId !== undefined}
                className="margin__left-pos--5-mobile datepicker"
                labelClass="datepicker__label form__label"
                datePickerClass='container__col-12 container__col-sm-5'
                label={t("personalInformationForm.immigrationDate")}
                onChange={(value) => {
                  setFieldValue('immigrationDate', value !== null ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : '');
                }}
              /> : ''}
          </div>
          <div className="container__row">
            <InputWithTitle
              name='occupation'
              required={isChild ? false : true}
              className='container__col-12 container__col-sm-5 height__min--85 margin__right-pos--5-mobile'
              label={t("personalInformationForm.occupation")}
              labelClassName="form__label"
              inputClassName="form__label--input"
              errorClassName="form__error"
              maxLength={50}
            />
            <InputWithTitle
              name='placeOfWorkOrStudy'
              required={isChild ? false : true}
              className="container__col-12 container__col-sm-5 height__min--85"
              label={t("personalInformationForm.placeOfWorkOrStudy")}
              labelClassName="form__label"
              inputClassName="form__label--input"
              errorClassName="form__error"
              maxLength={50}
            />
          </div>
          {isYoungChild ?
            <div className="container__row">
              <InputWithTitle
                name='height'
                labelClassName="form__label"
                label={t("personalInformationForm.height")}
                className='container__col-12 container__col-sm-5 height__min--85'
                inputClassName="form__label--input"
                errorClassName="form__error"
                maxLength={10}
              />
              <InputWithTitle
                name='weight'
                label={t("personalInformationForm.weight")}
                value={values.weight}
                className="container__col-12 container__col-sm-5 height__min--85 margin__left-pos--5-mobile"
                labelClassName="form__label"
                inputClassName="form__label--input"
                errorClassName="form__error"
                maxLength={10}
              />
            </div>
            : ''}
          {isYoungChild ?
            <div className="container__row">
              <NewRadioGroup
                name='bcg'
                className='container__col-sm-5 container__col-12 radiobutton'
                buttonWrapper='radiobutton__row'
                label={t('personalInformationForm.bcg')}
                labelClass="form__label"
                onChange={(e) => {
                  e !== YesNoUnknown.Yes.toString() && setFieldValue('bcgYear', '');
                  return JSON.parse(e);
                }}
                items={[
                  {
                    label: t('common.yes'),
                    value: YesNoUnknown.Yes,
                  },
                  {
                    label: t('common.no'),
                    value: YesNoUnknown.No,
                  },
                  {
                    label: t('common.unknown'),
                    value: YesNoUnknown.Unknown,
                  },
                ]}
              />
              <Dropdown
                disable={values.bcg !== YesNoUnknown.Yes}
                options={yearOptions}
                name='bcgYear'
                value={yearOptions.find(x => x.value===values.bcgYear)}
                className='container__col-sm-5 container__col-12'
                onChange={
                  (value: IOption) => {
                    setFieldValue('bcgYear', value.value);
                  }}
                label={t("personalInformationForm.bcgYear")}
                labelTextClass="form__label"
                inputClass='form__label--input'
              />
            </div>
            : ''}
          <div className="container__row">
            <NewRadioGroup
              name='alcoholUsage'
              className='radiobutton'
              label={t('personalInformationForm.alcoholUsage')}
              onChange={(e) => parseInt(e)}
              items={[
                {
                  label: t('personalInformationForm.optionNever'),
                  value: AlcoholUsage.Never,
                },
                {
                  label: t('personalInformationForm.optionLessThanOncePerMonth'),
                  value: AlcoholUsage.LessThanOncePerMonth,
                },
                {
                  label: t('personalInformationForm.optionOncePerMonth'),
                  value: AlcoholUsage.OncePerMonth,
                },
                {
                  label: t('personalInformationForm.optionOncePerWeek'),
                  value: AlcoholUsage.OncePerWeek,
                },
                {
                  label: t('personalInformationForm.optionDaily'),
                  value: AlcoholUsage.Daily,
                },
              ]}
              required={true}
            />
          </div>
          <div className="container__row">
            <NewRadioGroup
              name='waterpipeUsage'
              className='radiobutton'
              buttonWrapper='radiobutton__row'
              label={t('personalInformationForm.waterpipeUsage')}
              onChange={(e) => JSON.parse(e)}
              items={[
                {
                  label: t('common.yes'),
                  value: true,
                },
                {
                  label: t('common.no'),
                  value: false,
                },
              ]}
              required={true}
            />
          </div>
          <div className="container__row">
            <NewRadioGroup
              name='drugUsage'
              className='radiobutton'
              buttonWrapper='radiobutton__row'
              label={t('personalInformationForm.drugUsage')}
              onChange={e => JSON.parse(e)}
              items={[
                {
                  label: t('common.yes'),
                  value: true,
                },
                {
                  label: t('common.no'),
                  value: false,
                },
              ]}
              required={true}
            />
          </div>
          {values.drugUsage &&
            <div>
              <div className="container__row margin__left-pos--40">
                <NewRadioGroup
                  name='cannabisUsage'
                  className='radiobutton'
                  buttonWrapper='radiobutton__row'
                  label={t('personalInformationForm.cannabisUsage')}
                  onChange={e => JSON.parse(e)}
                  items={[
                    {
                      label: t('common.yes'),
                      value: true,
                    },
                    {
                      label: t('common.no'),
                      value: false,
                    },
                  ]}
                />
              </div>
              <div className="container__row margin__left-pos--40">
                <NewRadioGroup
                  name='intravenously'
                  className='radiobutton'
                  buttonWrapper='radiobutton__row'
                  label={t('personalInformationForm.intravenously')}
                  onChange={e => JSON.parse(e)}
                  items={[
                    {
                      label: t('common.yes'),
                      value: true,
                    },
                    {
                      label: t('common.no'),
                      value: false,
                    },
                  ]}
                />
              </div>
            </div>
          }
          <div className="container__row">
            <div className="container__col-lg-6">
              <NewRadioGroup
                name='pregnant'
                className='radiobutton'
                label={t('personalInformationForm.pregnant')}
                onChange={(e) => {
                  if (e !== '1') {
                    setFieldValue('pregnantDueDate', null);
                  }
                  return parseInt(e);
                }}
                items={[
                  {
                    label: t('common.yes'),
                    value: FourOptions.Yes,
                  },
                  {
                    label: t('common.no'),
                    value: FourOptions.No,
                  },
                  {
                    label: t('common.notConcern'),
                    value: FourOptions.NotConcern,
                  },
                  {
                    label: t('common.unknown'),
                    value: FourOptions.Unknown,
                  },
                ]}
                required={true}
              />
            </div>
            {values.pregnant === YesNoUnknown.Yes ?
              <div className="container__col-lg-6">
                <DatePicker
                  name="pregnantDueDate"
                  className="datepicker"
                  labelClass="datepicker__label form__label"
                  label={t("personalInformationForm.pregnantDueDate")}
                  onChange={(value) => {
                    setFieldValue('pregnantDueDate', dayjs(value).format("YYYY-MM-DD HH:mm:ss"));
                  }}
                />
              </div>
              : null}
          </div>
          <div aria-live="polite" className="container__row">
            <ValidationErrorMsg
              isValid={isValid}
              errorMsg={(t('formValidations.required-fields'))}
            />
          </div>
          <div className="container__row">
            <BottomNavigation
              primaryButtonClick={() => {
                validateForm().then(() => setIsValid(false));
                if (!values.drugUsage) {
                  setFieldValue('cannabisUsage', undefined);
                  setFieldValue('intravenously', undefined);
                }
                if (values.birthCountryId === finlandCountryId) {
                  setFieldValue('immigrationDate', null);
                }
                isChild ? setFieldValue('child', true) : setFieldValue('child', false);
                handleSubmit();
              }}
              primaryButtonClass="button__primary"
              primaryButtonLabel={t('buttons.continue')}
              secondaryButtonClick={async () => {
                await saveFormData(values);
                navigate(langPath('/tuberkuloosi'))
              }}
              secondaryButtonClass="button__secondary"
              secondaryButtonLabel={t('buttons.return')}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default PersonalInformationForm;

import React, { useEffect, useState } from 'react';
import { LayoutRouteProps, Outlet} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import dayjs from 'dayjs';
import { IMaintenance } from '../../models/IMaintenance';
import { AlertTypes } from '../../shared/enums/maintenanceAlerts';
import MaintenancePage from '../../pages/maintenancePage';
import { getCurrentLang } from '../../utils/languageUtils';

const IsMaintenanceRoute: React.FC<LayoutRouteProps> = (props) => {

    const maintenances = useSelector((state: RootState) => state.maintenances);

    const [ currentAlert, setCurrentAlert ] = useState<IMaintenance | null>(null);

    useEffect(() => {
        const today = dayjs();

        maintenances.data.filter(x => x.type === AlertTypes.Maintenance).filter((x => {
            if (today.isAfter(x.start)) {
                if (x.end) {
                    if (today.isAfter(x.end)) {
                        setCurrentAlert(null);
                    } else {
                        setCurrentAlert(x);
                    }
                } else {
                    setCurrentAlert(x);
                }
            }
        }));

    }, [maintenances]);

    let correctContent;

    switch(getCurrentLang()) {
        case 'fi':
            correctContent = currentAlert?.fiDescription;
            break;
        case 'sv':
            correctContent = currentAlert?.svDescription ?? currentAlert?.fiDescription;
            break;
        case 'en':
            correctContent = currentAlert?.enDescription ?? currentAlert?.fiDescription;
            break;
        default:
            correctContent = currentAlert?.fiDescription;
    }

    if (!maintenances.hasData) {
        return <div/>;
    }

    return currentAlert ? (
        <MaintenancePage>
            {correctContent}
        </MaintenancePage>
    ) : (
        <Outlet/>
    )
};

export default IsMaintenanceRoute;
import { t } from 'i18next';
import { SignoutResponse } from 'oidc-client';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SignoutCallbackComponent } from 'redux-oidc';
import i18n from '../../locales/i18n';
import { ISignOutModel } from '../../models/ISignOutModel';
import LoggedInUser from '../../models/LoggedInUser';
import LoginType from '../../models/LoginType';
import { AUTO_LOGOUT_KEY, INVALID_USER_KEY, LOGOUT_KEY, NO_RESULT_ERROR } from '../../shared/constants/logoutConstants';
import { setIsOnBehalf, setLoggedIn } from '../../state/settings/settingsSlice';
import { getCurrentLang } from '../../utils/languageUtils';
import { langPathWithLang } from '../../utils/pathUtils';
import userManager from '../../utils/userManager';
import { loginHelper } from '../../utils/userManagerHelpers';

const SingoutOidc: React.FC = () => {

  useEffect(() => {
    document.title = t('header.title')
  }, []);
  
  const dispatch = useDispatch();
  return (
    <SignoutCallbackComponent
      userManager={userManager}
      successCallback={(r) => {
        const state = r.state as ISignOutModel;
        dispatch(setIsOnBehalf(false));
        
        document.documentElement.setAttribute('lang', state?.lang || 'fi');

        if (state?.isOnBehalf) {
          const lang = getCurrentLang();
          return loginHelper({ type: LoginType.SIGNOUT_ON_BEHALF, lang });
        }

        switch (state.path) {
        
          case INVALID_USER_KEY:
            window.location.href = langPathWithLang('/virheellinen-kayttaja', state.lang);
            break;
          case LOGOUT_KEY:
          case AUTO_LOGOUT_KEY:
            dispatch(setLoggedIn(LoggedInUser.NO));
            window.location.href = langPathWithLang('/', state.lang);
            break;
          case NO_RESULT_ERROR:
            window.location.href = langPathWithLang('/ei-vastauksia', state.lang);
            break;
          default:
            window.location.href = langPathWithLang('/', state.lang);
            break;
        }
      }}
      errorCallback={(e) => console.log(e)}><div /></SignoutCallbackComponent>
  );
};

export default SingoutOidc;

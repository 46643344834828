import React, { createRef, useEffect, useState } from 'react';
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IOption } from "../../models/IOption";
import { Form, Formik } from "formik";
import Dropdown from "../../components/dropdown/dropdown";
import RadioGroup from "../../components/radiogroup/radioGroup";
import YesNoUnknown from "../../models/YesNoUnknown";
import { setFormType, setTuberculosisInfo } from '../../state/forms/formsSlice';
import FormType from "../../models/FormType";
import { RootState } from "../../state/store";
import yearOptions from "../../utils/yearOptions";
import { TuberculosisForm } from "../../models/TuberculosisForm";
import { postQuestionnaireResponse } from "../../services/questionnaireService";
import TooltipModal from "../../components/tooltipModal/tooltipModal";
import ValidationErrorMsg from '../../components/validationErrorMsg/validationErrorMsg';
import BottomNavigation from '../../components/bottomNavigation/bottomNavigation';
import InputWithTitle from '@husepidemic/epidemicdesign/inputWithTitle';
import { ConnectedFocusError } from 'focus-formik-error';
import NewRadioGroup from '../../components/newRadioGroup/newRadioGroup';

const SymptomsInformationForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formData = useSelector((state: RootState) => state.forms.tuberculosisForm);
  const [isValid, setIsValid] = useState(true);

  const initialFocus = createRef<any>();
  useEffect(() => { initialFocus.current?.focus(); }, []);

  const organOptions = [
    { label: t('tuberculosisOrgans.Lungs'), value: "Lungs" },
    { label: t('tuberculosisOrgans.LymphNode'), value: "LymphNode" },
    { label: t('tuberculosisOrgans.Bones'), value: "Bones" },
    { label: t('tuberculosisOrgans.Meninges'), value: "Meninges" },
    { label: t('tuberculosisOrgans.Kidney'), value: "Kidney" },
    { label: t('tuberculosisOrgans.Other'), value: "Other" },
    { label: t('tuberculosisOrgans.Unknown'), value: "Unknown" }
  ];

  const yesNoUnknownItems = [
    {
      label: t('common.yes'),
      value: YesNoUnknown.Yes,
    },
    {
      label: t('common.no'),
      value: YesNoUnknown.No,
    },
    {
      label: t('common.unknown'),
      value: YesNoUnknown.Unknown,
    },
  ];

  const validationSchema = Yup.object().shape({
    cough: Yup.string().required(t('formValidations.required-field-cough')),
    sputum: Yup.string().required(t('formValidations.required-field-sputum')),
    bloodyCough: Yup.string().required(t('formValidations.required-field-bloodyCough')),
    shortnessOfBreathOnExertion: Yup.string().required(t('formValidations.required-field-shortnessOfBreathOnExertion')),
    tiredness: Yup.string().required(t('formValidations.required-field-tiredness')),
    lossOfApetite: Yup.string().required(t('formValidations.required-field-lossOfApetite')),
    weightLoss: Yup.string().required(t('formValidations.required-field-weightLoss')),
    nightSweats: Yup.string().required(t('formValidations.required-field-nightSweats')),
    unexaplainedFever: Yup.string().required(t('formValidations.required-field-unexplainedFever')),
    hadPreviousTuberculosis: Yup.string().required(t('formValidations.required-field-hadPreviousTuberculosis')),
    previousTuberculosisExposure: Yup.string().required(t('formValidations.required-field-previousTuberculosisExposure')),

    previousTuberculosisWhen: Yup.string().when('hadPreviousTuberculosis', {
      is: (x: string) => parseInt(x) === YesNoUnknown.Yes,
      then: Yup.string().required(t('formValidations.required-field-previousTuberculosisWhen')),
    }),
    previousTuberculosisOrgan: Yup.string().when('hadPreviousTuberculosis', {
      is: (x: string) => parseInt(x) === YesNoUnknown.Yes,
      then: Yup.string().required(t('formValidations.required-field-previousTuberculosisOrgan')),
    }),

    previousTuberculosisExposureWhen: Yup.string().when('previousTuberculosisExposure', {
      is: (x: string) => parseInt(x) === YesNoUnknown.Yes,
      then: Yup.string().required(t('formValidations.required-field-previousTuberculosisExposureWhen')),
    }),
    previousTuberculosisExposureLocation: Yup.string().when('previousTuberculosisExposure', {
      is: (x: string) => parseInt(x) === YesNoUnknown.Yes,
      then: Yup.string().required(t('formValidations.required-field-previousTuberculosisExposureLocation')),
    })
  });

  const handleSubmit = async (values: TuberculosisForm) => {
    setIsValid(true);
    await saveFormData(values);
    dispatch(setFormType(FormType.READY));
  }

  useEffect(() => {
    document.title = t('symptomsForm.title')
  }, []);

  const saveFormData = async (values: TuberculosisForm) => {
    await postQuestionnaireResponse("tuberculosis", values)
      .then(() => {
        dispatch(setTuberculosisInfo(values));
      });
  }

  const [showWeightLossInfo, setShowWeightLossInfo] = useState(false);
  const [focusOnModal, setFocusOnModal] = useState(false);
  const [focusOnQuestion, setFocusOnQuestion] = useState('');

  useEffect(() => {
    setFocusOnModal(!focusOnModal);
    if (focusOnQuestion === 'weightLoss') { document.getElementById('weightLossHeader')?.focus(); setFocusOnQuestion(''); };
  }, [showWeightLossInfo])

  return (
    <div className='container'>
      <h1 ref={initialFocus} tabIndex={-1} className="form-header">{t('symptomsForm.title')}</h1>
      <h3 className="form-header__sub">{t('symptomsForm.description')}</h3>
      <h5 className="form-header__sub--small">{t('symptomsForm.symptomsListDescription')}</h5>

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={validationSchema}
        onSubmit={async (values: TuberculosisForm) => {
          await handleSubmit(values);
        }}
      >
        {({ values, setFieldValue, errors, validateForm, handleSubmit }) => (
          <Form noValidate className='container'>
            <ConnectedFocusError />
            <div className="container__row">
              <NewRadioGroup
                name='cough'
                label={t('symptomsForm.cough')}
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                onChange={e => parseInt(e)}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            <div className="container__row">
              <NewRadioGroup
                name='sputum'
                label={t('symptomsForm.sputum')}
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                onChange={e => parseInt(e)}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            <div className="container__row">
              <NewRadioGroup
                name='bloodyCough'
                label={t('symptomsForm.bloodyCough')}
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                onChange={e => parseInt(e)}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            <div className="container__row">
              <NewRadioGroup
                name='shortnessOfBreathOnExertion'
                label={t('symptomsForm.shortnessOfBreathOnExertion')}
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                onChange={e => parseInt(e)}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            <div className="container__row">
              <NewRadioGroup
                name='tiredness'
                label={t('symptomsForm.tiredness')}
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                onChange={e => parseInt(e)}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            <div className="container__row">
              <NewRadioGroup
                name='lossOfApetite'
                label={t('symptomsForm.lossOfApetite')}
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                onChange={e => parseInt(e)}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            <div className="container__row">
              <NewRadioGroup
                name='weightLoss'
                label={t('symptomsForm.weightLoss')}
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                onChange={e => parseInt(e)}
                items={yesNoUnknownItems}
                required={true}
                iconClick={() => setShowWeightLossInfo(true)}
              />
              <TooltipModal
                show={showWeightLossInfo}
                tooltipHeader={t('symptomsForm.weightLoss')}
                tooltipText={t('symptomsForm.weightLossTooltipInfo')}
                closeButtonText={t('common.close')}
                onCloseButtonClick={() => { setShowWeightLossInfo(false); setFocusOnQuestion('weightLoss') }}
                trapModalItems="h2, p, button:not([disabled])"
              />
            </div>
            <div className="container__row">
              <NewRadioGroup
                name='nightSweats'
                label={t('symptomsForm.nightSweats')}
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                onChange={e => parseInt(e)}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            <div className="container__row">
              <NewRadioGroup
                name='unexaplainedFever'
                label={t('symptomsForm.unexaplainedFever')}
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                onChange={e => parseInt(e)}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            <div className="container__row">
              <InputWithTitle
                name='otherSymptoms'
                className='container__col-12 container__col-sm-11 height__min--85'
                label={t("symptomsForm.otherSymptoms")}
                labelClassName="form__label"
                inputClassName="form__label--input"
                maxLength={1000}
              />
            </div>
            <div className="container__row">
              <NewRadioGroup
                name='hadPreviousTuberculosis'
                label={t('symptomsForm.hadPreviousTuberculosis')}
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                onChange={(e) => {
                  if (parseInt(e) !== YesNoUnknown.Yes) {
                    setFieldValue('previousTuberculosisWhen', '');
                    setFieldValue('previousTuberculosisOrgan', '');
                    setFieldValue('previousTuberculosisMedication', '');
                  }
                  return parseInt(e);
                }}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            {values.hadPreviousTuberculosis === YesNoUnknown.Yes &&
              <div className="container__row">
                <Dropdown
                  name='previousTuberculosisWhen'
                  className='container__col-12 container__col-sm-5'
                  options={yearOptions}
                  required
                  value={yearOptions.find(x => x.value === values.previousTuberculosisWhen)}
                  onChange={
                    (value: IOption) => {
                      setFieldValue('previousTuberculosisWhen', value.value)
                    }}
                  label={t("symptomsForm.previousTuberculosisWhen")}
                  labelTextClass="form__label"
                  inputClass='form__label--input'
                />
                <div className="container__col-12 container__col-sm-1"></div>
                <Dropdown
                  name='previousTuberculosisOrgan'
                  className='container__col-12 container__col-sm-5'
                  options={organOptions}
                  required
                  value={organOptions.find(x => x.value === values.previousTuberculosisOrgan)}
                  onChange={
                    (option: IOption) => {
                      setFieldValue('previousTuberculosisOrgan', option.value)
                    }}
                  label={t("symptomsForm.previousTuberculosisOrgan")}
                  labelTextClass="form__label"
                  inputClass='form__label--input'
                />
              </div>
            }
            {values.hadPreviousTuberculosis === YesNoUnknown.Yes &&
              <div className="container__row">
                <InputWithTitle
                  name='previousTuberculosisMedication'
                  className='container__col-12 container__col-sm-11 height__min--85'
                  label={t("symptomsForm.previousTuberculosisMedication")}
                  labelClassName="form__label"
                  inputClassName="form__label--input"
                  maxLength={1000}
                />
              </div>
            }
            <div className="container__col-12 container-md-6">
              <NewRadioGroup
                name='previousTuberculosisExposure'
                label={t('symptomsForm.previousTuberculosisExposure')}
                className='radiobutton'
                buttonWrapper='radiobutton__row'
                onChange={(e) => {
                  if (parseInt(e) !== YesNoUnknown.Yes) {
                    setFieldValue('previousTuberculosisExposureWhen', '');
                    setFieldValue('previousTuberculosisExposureLocation', '');
                  }
                  return parseInt(e);
                }}
                items={yesNoUnknownItems}
                required={true}
              />
            </div>
            {values.previousTuberculosisExposure === YesNoUnknown.Yes &&
              <div className="container__row">
                <Dropdown
                  name='previousTuberculosisExposureWhen'
                  className='container__col-12 container__col-sm-5'
                  options={yearOptions}
                  required
                  value={yearOptions.find(x => x.value === values.previousTuberculosisExposureWhen)}
                  onChange={
                    (option: IOption) => {
                      setFieldValue('previousTuberculosisExposureWhen', option.value)
                    }}
                  label={t("symptomsForm.previousTuberculosisExposureWhen")}
                  labelTextClass="form__label"
                  inputClass='form__label--input'
                />
                <div className="container__col-12 container__col-sm-1"></div>
                <InputWithTitle
                  name='previousTuberculosisExposureLocation'
                  className='container__col-12 container__col-sm-5 height__min--85'
                  required
                  label={t("symptomsForm.previousTuberculosisExposureLocation")}
                  labelClassName="form__label"
                  inputClassName="form__label--input"
                  errorClassName="form__error"
                  maxLength={1000}
                />
              </div>
            }
            <div aria-live='polite' className="container__row">
              <ValidationErrorMsg
                isValid={isValid}
                errorMsg={(t('formValidations.required-fields'))}
              />
            </div>
            <div className="container__row">
              <BottomNavigation
                primaryButtonClick={() => {
                  validateForm().then(() => setIsValid(false));
                  handleSubmit();
                }}
                primaryButtonClass="button__primary"
                primaryButtonLabel={t('buttons.continue')}
                secondaryButtonClick={async () => {
                  await saveFormData(values);
                  dispatch(setFormType(FormType.GENERAL_MEDICAL_INFO));
                }}
                secondaryButtonClass="button__secondary"
                secondaryButtonLabel={t('buttons.return')}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div >
  );
}

export default SymptomsInformationForm;

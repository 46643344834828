import axios from "axios";
import { SurveillanceResponse } from "../models/SurveillanceResponse";
import userManager from "../utils/userManager";

export const getSurveillanceForUser = async (diseaseIdentifier: string): Promise<SurveillanceResponse | undefined> => {
  const user = await userManager.getUser();
  if (!user) return;
  const result = await axios.get(`${process.env.REACT_APP_API_URL}surveillance/${diseaseIdentifier}`, {
    headers: {
      Authorization: `${user.token_type} ${user.access_token}`,
    },
  })
  return result.data;
}


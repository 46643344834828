import { createSlice } from '@reduxjs/toolkit';

export interface SettingsState {
    isOnBehalf: boolean;
    loggedIn: number;
    isProfessional: boolean;
}

const initialState: SettingsState = {
    isOnBehalf: false,
    loggedIn: 0,
    isProfessional: false,
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setIsOnBehalf: (state, action) => {
            state.isOnBehalf = action.payload;
        },
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload;
        },
        setIsProfessional: (state, action) => {
            state.isProfessional = action.payload;
        }
    },
    extraReducers: (builder => {
    })
})

export const { setIsOnBehalf, setLoggedIn, setIsProfessional } = settingsSlice.actions

export default settingsSlice.reducer
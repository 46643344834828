import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './tooltipModal.module.scss';
import commonStyles from '../../assets/styles/common.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


interface ModalProps {
  show?: boolean;
  tooltipHeader: string;
  tooltipText: string;
  closeButtonText: string;
  onCloseButtonClick?: () => void;
  trapModalItems?: string;
}

const TooltipModal: React.FC<ModalProps> = ({ show, tooltipHeader, tooltipText, closeButtonText, onCloseButtonClick, trapModalItems }) => {
  const [isHidden, setIsHidden] = useState(true);
  const modal = useRef(null);

  useEffect(() => {
    let subscribe = true;
    if (!subscribe) return;
    setIsHidden(!show);
    return () => {
      subscribe = false;
    };
  }, [show]);


  const trapFocus = useCallback((element: HTMLElement | null, selectItems: string) => {
    if (element !== null) {
      const focusOnTheseElements = Array.from(
        element.querySelectorAll(selectItems)
      );
      const firstElement = focusOnTheseElements[0];
      const lastElement = focusOnTheseElements[focusOnTheseElements.length - 1];
      let currentFocus: HTMLElement | null | unknown = null;

      (firstElement as HTMLElement).focus();
      currentFocus = firstElement;

      const handleFocus = (e: any) => {
        e.preventDefault();
        if (focusOnTheseElements.includes(e.target)) {
          currentFocus = e.target;
        } else {
          if (currentFocus === firstElement) {
            (lastElement as HTMLElement).focus();
          } else {
            (firstElement as HTMLElement).focus();
          }
          currentFocus = document.activeElement;
        }
      };

      const closeWithEsc = (e: { keyCode: number; }) => {
        if (e.keyCode === 27) {
          if (onCloseButtonClick) {
            onCloseButtonClick();
          }
        }
      }

      document.addEventListener("focus", handleFocus, true);
      document.addEventListener("keydown", closeWithEsc);

      return {
        onClose: () => {
          document.removeEventListener("focus", handleFocus, true);
          document.removeEventListener("keydown", closeWithEsc);
        }
      }
    };
  }, [onCloseButtonClick]);

  useEffect(() => {
    if (!show) return;
    if (trapModalItems) {
      const trapped = trapFocus(modal.current, trapModalItems);
      return () => {
        if (trapped) {
          trapped.onClose();
        }
      };
    }
  }, [show, trapFocus]);

  return isHidden ? null : (
    <div
      aria-modal="true"
      className={styles.modal}
      ref={modal}
    >
      <div className={styles.modalWrapper}>
        <div className={styles.modalContent}>
          <div className="container">
            <div className="container__row">
              <div className="container__col-1">
                <FontAwesomeIcon className={styles.infoIcon} icon={faInfoCircle} />
              </div>
              <div className={`container__col-7 ${commonStyles.tooltipFont}`}>
                <h1 tabIndex={0} className={commonStyles.title} id="modalHeader">{tooltipHeader + '?'}</h1>
                <p tabIndex={0} id="modalText">{tooltipText}</p>
                <div className="container__row margin__top-pos--10">
                  <button
                    tabIndex={0}
                    type="button"
                    className={`${commonStyles.primaryButton} ${styles.buttonWidth}`}
                    onClick={() => (onCloseButtonClick ? onCloseButtonClick() : null)}
                  >
                    {closeButtonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TooltipModal.defaultProps = {
  show: false,
};

export default TooltipModal;

import React, { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { IPolicyLink } from '../../models/IPolicyLink';
import styles from './personalInfoPolicyContent.module.scss';
import commonStyles from "../../assets/styles/common.module.scss";

const NoResultErrorContent: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('header.title')
  }, []);

  const initialFocus = createRef<any>();
  useEffect(() => { initialFocus.current?.focus(); }, []);

  const policyLinks: IPolicyLink[] = [
    {
      url: 'personalInfoPolicy.url-1',
      city: 'personalInfoPolicy.link-1',
    },
    {
      url: 'personalInfoPolicy.url-2',
      city: 'personalInfoPolicy.link-2',
    },
    {
      url: 'personalInfoPolicy.url-3',
      city: 'personalInfoPolicy.link-3',
    },
    {
      url: 'personalInfoPolicy.url-4',
      city: 'personalInfoPolicy.link-4',
    },
    {
      url: 'personalInfoPolicy.url-5',
      city: 'personalInfoPolicy.link-5',
    },
    {
      url: 'personalInfoPolicy.url-6',
      city: 'personalInfoPolicy.link-6',
    }
  ];

  return (
    <div className="container">
      <div className="container__row">
        <h1 ref={initialFocus} tabIndex={-1} className={styles.title}>{t('personalInfoPolicy.title')}</h1>
      </div>
      <div className={`container__row ${styles.paddingBottom}`}>
        <div className="container__col-12">
          <p className={commonStyles.formLabelValue}>{t('personalInfoPolicy.text-1')}</p>
        </div>
      </div>
      <div className={`container__row ${styles.paddingBottom}`}>
        <div className="container__col-12">
          <p className={commonStyles.formLabelValue}>{t('personalInfoPolicy.text-2')}</p>
        </div>
      </div>
      <div className={`container__row ${styles.paddingBottom}`}>
        <div className="container__col-12">
          <p className={commonStyles.formLabelValue}>{t('personalInfoPolicy.text-3')}</p>
        </div>
      </div>
      <ul>
        {policyLinks
          .sort((a, b) => {
            if (a.city < b.city) return -1;
            if (a.city > b.city) return 1;
            return 0;
          })
          .map((link) => {
            return (
              <li key={link.city}>
                <a
                  href={t(link.url)}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <b className={commonStyles.formLabelValue}>{t(link.city)}</b>
                  <FontAwesomeIcon
                    title={t('linkTooltips.newTab')}
                    className={styles.newTabLinkIcon}
                    icon={faExternalLinkSquareAlt} />
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default NoResultErrorContent;

import React, { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './summaryAndSending.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import FormType from '../../models/FormType';
import { setFormType, setTuberculosisInfo } from '../../state/forms/formsSlice';
import { RootState } from "../../state/store";
import dayjs from "dayjs";
import { postQuestionnaireResponse } from '../../services/questionnaireService';
import { TuberculosisForm } from '../../models/TuberculosisForm';
import { useNavigate } from 'react-router-dom';
import { langPath } from '../../utils/pathUtils';
import YesNoUnknown from '../../models/YesNoUnknown';
import BottomNavigation from '../../components/bottomNavigation/bottomNavigation';
import AlcoholUsage from '../../models/enums/AlcoholUsage';
import FourOptions from '../../models/fourOptions';

const SummaryAndSendingPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formData = useSelector((state: RootState) => state.forms.tuberculosisForm);
  const userDetails = useSelector((state: RootState) => state.userDetails.userDetails);
  const { languages } = useSelector((state: RootState) => state.forms);

  const initialFocus = createRef<any>();
  useEffect(() => { initialFocus.current?.focus(); }, []);

  const alcoholUsage = (value: number | undefined) => {
    if (value === undefined) return '';
    switch (value) {
      case AlcoholUsage.Never:
        return t('personalInformationForm.optionNever')
      case AlcoholUsage.LessThanOncePerMonth:
        return t('personalInformationForm.optionLessThanOncePerMonth')
      case AlcoholUsage.OncePerMonth:
        return t('personalInformationForm.optionOncePerMonth')
      case AlcoholUsage.OncePerWeek:
        return t('personalInformationForm.optionOncePerWeek')
      case AlcoholUsage.Daily:
        return t('personalInformationForm.optionDaily')
      default:
        break;
    }
  }

  const pregnant = (value: number | undefined) => {
    if (value === undefined) return '';
    switch (value) {
      case FourOptions.Yes:
        return t('common.yes')
      case FourOptions.No:
        return t('common.no')
      case FourOptions.Unknown:
        return t('common.unknown')
      case FourOptions.NotConcern:
        return t('common.notConcern')
      default:
        break;
    }
  }

  const YesNoUnknownTransform = (value: YesNoUnknown | undefined) => {
    if (value === undefined) return '';
    switch (value) {
      case YesNoUnknown.Yes:
        return t('common.yes');
      case YesNoUnknown.No:
        return t('common.no');
      case YesNoUnknown.Unknown:
        return t('common.unknown');
      default:
        break;
    }
  }

  useEffect(() => {
    document.title = t('summaryView.title')
  }, []);

  const onSend = async () => {
    await saveFormData({ ...formData, published: new Date() });
    navigate(langPath('/tuberkuloosi'))
  }

  const saveFormData = async (values: TuberculosisForm) => {
    await postQuestionnaireResponse("tuberculosis", values)
      .then(() => {
        dispatch(setTuberculosisInfo(values));
      });
  }

  const handlePreviousPage = () => {
    formData.published !== undefined ?
      navigate(langPath('/tuberkuloosi')) :
      dispatch(setFormType(FormType.SYMPTOMS))
  }

  return (
    <div className='container'>
      <h1 ref={initialFocus} tabIndex={-1} className="form-header">{t("summaryView.titleMain")}</h1>
      <h5 className="form-header__sub--small-bold">{t("personalInformationForm.personalInformation")}</h5>

      <div className="container__row">
        <span className="form__label">{t("properties.name")}:</span>
        <span className={styles.valueText}>{userDetails?.firstName && userDetails?.lastName ? userDetails?.firstName?.toUpperCase() + ", " + userDetails?.lastName?.toUpperCase() : ""}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("properties.ssn")}:</span>
        <span className={styles.valueText}>{userDetails?.maskedSsn}</span>
      </div>
      <div className="container__row">
        <h5 className="form-header__sub--small-bold">{t("personalInformationForm.contactInformation")}</h5>
      </div>
      <div className="container__row">
        <span className="form__label">{t("properties.homeMunicipality")}:</span>
        <span className={styles.valueText}>{userDetails?.address?.locality?.toUpperCase()}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("properties.streetAddress")}:</span>
        <span className={styles.valueText}>{userDetails?.address?.postalAddress?.toUpperCase()}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("properties.postalCode")}:</span>
        <span className={styles.valueText}>{userDetails?.address?.postalCode}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("personalInformationForm.phoneNumber")}:</span>
        <span className={styles.valueText}>{formData.phoneNumber}</span>
      </div>
      {formData.communicationLanguage &&
        <div className="container__row">
          <span className="form__label">{t("personalInformationForm.communicationLanguage")}:</span>
          <span className={styles.valueText}>{languages.find(x => x.value === formData.communicationLanguage)?.label}</span>
        </div>
      }
      {formData.residenceMunicipality?.label &&
        <div className="container__row">
          <span className="form__label">{t("personalInformationForm.residenceMunicipality")}:</span>
          <span className={styles.valueText}>{formData.residenceMunicipality?.label}</span>
        </div>
      }
      <div className="container__row">
        <span className="form__label">{t("personalInformationForm.streetAddress")}:</span>
        <span className={styles.valueText}>{formData.streetAddress}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("personalInformationForm.postalCode")}:</span>
        <span className={styles.valueText}>{formData.postalCode}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("personalInformationForm.birthCountry")}:</span>
        <span className={styles.valueText}>{formData.birthCountry?.label}</span>
      </div>
      {formData.immigrationDate &&
        <div className="container__row">
          <span className="form__label">{t("personalInformationForm.immigrationDate")}:</span>
          <span className={styles.valueText}>{dayjs(formData.immigrationDate).format('DD.MM.YYYY')}</span>
        </div>
      }
      {formData.occupation &&
        <div className="container__row">
          <span className="form__label">{t("personalInformationForm.occupation")}:</span>
          <span className={styles.valueText}>{formData.occupation}</span>
        </div>
      }
      {formData.placeOfWorkOrStudy &&
        <div className="container__row">
          <span className="form__label">{t("personalInformationForm.placeOfWorkOrStudy")}:</span>
          <span className={styles.valueText}>{formData.placeOfWorkOrStudy}</span>
        </div>
      }
      <div className="container__row">
        <span className="form__label">{t("personalInformationForm.child")}:</span>
        <span className={styles.valueText}>{formData.child ? t("common.yes") : t("common.no")}</span>
      </div>
      {formData.height &&
        <div className="container__row">
          <span className="form__label">{t("personalInformationForm.height")}:</span>
          <span className={styles.valueText}>{formData.height}</span>
        </div>
      }
      {formData.weight &&
        <div className="container__row">
          <span className="form__label">{t("personalInformationForm.weight")}:</span>
          <span className={styles.valueText}>{formData.weight}</span>
        </div>
      }
      {formData.bcg &&
        <div className="container__row">
          <span className="form__label">{t("personalInformationForm.bcg")}:</span>
          <span className={styles.valueText}>{YesNoUnknownTransform(formData.bcg)}</span>
        </div>
      }
      {formData.bcgYear && formData.bcg === YesNoUnknown.Yes &&
        <div className="container__row">
          <span className="form__label">{t("personalInformationForm.bcgYear")}:</span>
          <span className={styles.valueText}>{formData.bcgYear}</span>
        </div>
      }
      <br />
      <div className="container__row">
        <span className="form__label">{t("summaryView.howOftenGetWasted")}:</span>
        <span className={styles.valueText}>{alcoholUsage(formData.alcoholUsage)}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("personalInformationForm.waterpipeUsage")}:</span>
        <span className={styles.valueText}>{formData.waterpipeUsage ? t("common.yes") : t("common.no")}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("personalInformationForm.drugUsage")}:</span>
        <span className={styles.valueText}>{formData.drugUsage ? t("common.yes") : t("common.no")}</span>
      </div>
      {formData.drugUsage &&
        <div className="container__row">
          <span className="form__label">{t("personalInformationForm.cannabisUsage")}:</span>
          <span className={styles.valueText}>{formData.cannabisUsage ? t("common.yes") : t("common.no")}</span>
        </div>
      }
      {formData.drugUsage &&
        <div className="container__row">
          <span className="form__label">{t("personalInformationForm.intravenously")}:</span>
          <span className={styles.valueText}>{formData.intravenously ? t("common.yes") : t("common.no")}</span>
        </div>
      }
      <div className="container__row">
        <span className="form__label">{t("personalInformationForm.pregnant")}:</span>
        <span className={styles.valueText}>{pregnant(formData.pregnant)}</span>
      </div>
      {formData.pregnantDueDate &&
        <div className="container__row">
          <span className="form__label">{t("personalInformationForm.pregnantDueDate")}:</span>
          <span className={styles.valueText}>{dayjs(formData.pregnantDueDate).format('DD.MM.YYYY')}</span>
        </div>
      }
      <div className="container__row">
        <h1 className="form-header padding__top-pos--24">{t("generalMedicalInfoForm.generalMedicalInfoHeader")}</h1>
      </div>
      <div className="container__row">
        <span className="form__label">{t("generalMedicalInfoForm.uremia")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.uremia)}</span>
      </div>
      {formData.dialysisStarted &&
        <div className="container__row">
          <span className="form__label">{t("generalMedicalInfoForm.dialysisStarted")}:</span>
          <span className={styles.valueText}>{formData.dialysisStarted}</span>
        </div>
      }
      <div className="container__row">
        <span className="form__label">{t("generalMedicalInfoForm.organTransplant")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.organTransplant)}</span>
      </div>
      {formData.transplantDescription &&
        <div className="container__row">
          <span className="form__label">{t("generalMedicalInfoForm.transplantDescription")}:</span>
          <span className={styles.valueText}>{formData.transplantDescription}</span>
        </div>
      }
      {formData.transplantDone &&
        <div className="container__row">
          <span className="form__label">{t("generalMedicalInfoForm.transplantDone")}:</span>
          <span className={styles.valueText}>{formData.transplantDone}</span>
        </div>
      }
      <div className="container__row">
        <span className="form__label">{t("generalMedicalInfoForm.hiv")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.hiv)}</span>
      </div>
      {formData.hiv === YesNoUnknown.Yes &&
        <div className="container__row">
          <span className="form__label">{t("generalMedicalInfoForm.hivMedication")}:</span>
          <span className={styles.valueText}>{YesNoUnknownTransform(formData.hivMedication)}</span>
        </div>
      }
      {
        formData.hivMedication === YesNoUnknown.Yes &&
        <div className="container__row">
          <span className="form__label">{t("generalMedicalInfoForm.hivMedicationWhen")}:</span>
          <span className={styles.valueText}>{formData.hivMedicationWhen}</span>
        </div>
      }
      <div className="container__row">
        <span className="form__label">{t("generalMedicalInfoForm.cytostaticMedicationInUse")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.cytostaticMedicationInUse)}</span>
      </div>
      {formData.cytostaticMedicationDisease &&
        <div className="container__row">
          <span className="form__label">{t("generalMedicalInfoForm.cytostaticMedicationDisease")}:</span>
          <span className={styles.valueText}>{formData.cytostaticMedicationDisease}</span>
        </div>
      }
      {formData.cytostaticMedication &&
        <div className="container__row">
          <span className="form__label">{t("generalMedicalInfoForm.cytostaticMedication")}:</span>
          <span className={styles.valueText}>{formData.cytostaticMedication}</span>
        </div>
      }
      <div className="container__row">
        <span className="form__label">{t("generalMedicalInfoForm.tnfInhibitorMedicationInUse")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.tnfInhibitorMedicationInUse)}</span>
      </div>
      {formData.tnfInhibitorMedicationDisease &&
        <div className="container__row">
          <span className="form__label">{t("generalMedicalInfoForm.tnfInhibitorMedicationDisease")}:</span>
          <span className={styles.valueText}>{formData.tnfInhibitorMedicationDisease}</span>
        </div>
      }
      {formData.tnfInhibitorMedication &&
        <div className="container__row">
          <span className="form__label">{t("generalMedicalInfoForm.tnfInhibitorMedication")}:</span>
          <span className={styles.valueText}> {formData.tnfInhibitorMedication}</span>
        </div>
      }
      <div className="container__row">
        <span className="form__label">{t("generalMedicalInfoForm.cortisoneMedicationInUse")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.cortisoneMedicationInUse)}</span>
      </div>
      {formData.cortisoneMedicationDisease &&
        <div className="container__row">
          <span className="form__label">{t("generalMedicalInfoForm.cortisoneMedicationDisease")}:</span>
          <span className={styles.valueText}>{formData.cortisoneMedicationDisease}</span>
        </div>
      }
      {formData.cortisoneMedication &&
        <div className="container__row">
          <span className="form__label">{t("generalMedicalInfoForm.cortisoneMedication")}:</span>
          <span className={styles.valueText}>{formData.cortisoneMedication}</span>
        </div>
      }
      <div className="container__row">
        <span className="form__label">{t("generalMedicalInfoForm.silicosis")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.silicosis)}</span>
      </div>
      <div className="container__row">
        <h1 className="form-header padding__top-pos--24">{t("symptomsForm.title")}</h1>
      </div>
      <div className="container__row">
        <span className="form__label">{t("symptomsForm.cough")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.cough)}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("symptomsForm.sputum")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.sputum)}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("symptomsForm.bloodyCough")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.bloodyCough)}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("symptomsForm.shortnessOfBreathOnExertion")}:</span>
        <span className={styles.valueText}> {YesNoUnknownTransform(formData.shortnessOfBreathOnExertion)}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("symptomsForm.tiredness")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.tiredness)}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("symptomsForm.lossOfApetite")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.lossOfApetite)}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("symptomsForm.weightLoss")}:</span>
        <span className={styles.valueText}> {YesNoUnknownTransform(formData.weightLoss)}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("symptomsForm.nightSweats")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.nightSweats)}</span>
      </div>
      <div className="container__row">
        <span className="form__label">{t("symptomsForm.unexaplainedFever")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.unexaplainedFever)}</span>
      </div>
      {formData.otherSymptoms &&
        <div className="container__row">
          <span className="form__label">{t("symptomsForm.otherSymptoms")}:</span>
          <span className={styles.valueText}>{formData.otherSymptoms}</span>
        </div>
      }
      <div className="container__row">
        <span className="form__label">{t("symptomsForm.hadPreviousTuberculosis")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.hadPreviousTuberculosis)}</span>
      </div>
      {formData.hadPreviousTuberculosis === YesNoUnknown.Yes &&
        <div className="container__row">
          <span className="form__label">{t("symptomsForm.previousTuberculosisWhen")}:</span>
          <span className={styles.valueText}> {formData.previousTuberculosisWhen}</span>
        </div>
      }
      {formData.hadPreviousTuberculosis === YesNoUnknown.Yes &&
        <div className="container__row">
          <span className="form__label">{t("symptomsForm.previousTuberculosisOrgan")}:</span>
          <span className={styles.valueText}>{t(`tuberculosisOrgans.${formData.previousTuberculosisOrgan}`)}</span>
        </div>
      }
      {formData.previousTuberculosisMedication &&
        <div className="container__row">
          <span className="form__label">{t("symptomsForm.previousTuberculosisMedication")}:</span>
          <span className={styles.valueText}>{formData.previousTuberculosisMedication}</span>
        </div>
      }
      <div className="container__row">
        <span className="form__label">{t("symptomsForm.previousTuberculosisExposure")}:</span>
        <span className={styles.valueText}>{YesNoUnknownTransform(formData.previousTuberculosisExposure)}</span>
      </div>
      {formData.previousTuberculosisExposureWhen &&
        <div className="container__row">
          <span className="form__label">{t("symptomsForm.previousTuberculosisExposureWhen")}:</span>
          <span className={styles.valueText}>{formData.previousTuberculosisExposureWhen}</span>
        </div>
      }
      {formData.previousTuberculosisExposureLocation &&
        <div className="container__row">
          <span className="form__label">{t("symptomsForm.previousTuberculosisExposureLocation")}:</span>
          <span className={styles.valueText}>{formData.previousTuberculosisExposureLocation}</span>
        </div>
      }
      <div className="container__row">
        <BottomNavigation
          primaryButtonClick={onSend}
          primaryButtonClass="button__primary"
          primaryButtonLabel={t('buttons.send')}
          primaryButtonHidden={formData.published !== undefined}
          secondaryButtonClick={handlePreviousPage}
          secondaryButtonClass="button__secondary"
          secondaryButtonLabel={t('buttons.return')}
        />
      </div>
    </div >
  )
}

export default SummaryAndSendingPage;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationFi from "./fi/strings.json";
import translationSv from "./sv/strings.json";
import translationEn from "./en/strings.json";
import { getCurrentLang } from "../utils/languageUtils";

const resources = {
  fi: {
    translation: translationFi,
  },
  en: {
    translation: translationEn,
  },
  sv: {
    translation: translationSv,
  },
};

const locale = getCurrentLang();

i18n.use(initReactI18next).init({
  resources,
  lng: locale,
  fallbackLng: "fi",
  keySeparator: ".",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;

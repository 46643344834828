import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AccordionPanel from "../../components/accordion/accordionPanel";
import ActionBox from "../../components/actionBox/actionBox";
import styles from "./tuberculosis.module.scss";
import {
  faHeartPulse
} from '@fortawesome/free-solid-svg-icons';
import { langPath } from "../../utils/pathUtils";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import LoadingStatus from "../../models/enums/LoadingStatus";
import { RootState } from "../../state/store";
import { createRef, useEffect } from "react";

const TuberculosisPageContent: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formData = useSelector((state: RootState) => state.forms.tuberculosisForm)
  const userDetails = useSelector((state: RootState) => state.userDetails);

  useEffect(() => {
    document.title = t('header.title')
  }, []);

  const heading = createRef<any>();
  useEffect(() => {
    heading.current?.focus();
  }, [userDetails]);

  return (
    <div className={`container ${styles.tuberculosisContainer}`}>
      <div className="container__row">
        <div className="container__col-12">
          <p ref={heading} tabIndex={-1} className={styles.title}>{t("tuberculosis.title1")}</p>
        </div></div>
      <div className="container__row">
        <div className="container__col-12">
          <p className={styles.content}>{t("tuberculosis.info1")}</p>
        </div>
      </div>
      <div className="container__row">
        <div className="container__col-12">
          <p className={styles.content}>{t("tuberculosis.info2")}</p>
        </div>
      </div>
      <div className="container__row">
        <div className="container__col-12">
          <AccordionPanel title={t("tuberculosis.accordionTitle")} content={t("tuberculosis.content1")} />
        </div>
      </div>
      <div className="container__row">
        <div className="container__col-12">
          <p className={styles.content}>{t("tuberculosis.content2")}</p>
        </div>
      </div>
      {userDetails.isUnderSurveillance && userDetails.fetchingSurveillanceDetails === LoadingStatus.Succeeded &&
        <div className="container__row">
          <div className="container__col-12">
            <ActionBox
              iconSize="xs"
              title={t('tuberculosis.title2')}
              note={Object.keys(formData).length > 0 ? (formData.published !== undefined ? `${t('tuberculosis.published')} ${dayjs(formData.published).format('DD.MM.YYYY')}` : t('tuberculosis.unPublished')) : undefined}
              noteClassName={formData.published !== undefined ? 'published' : 'unpublished'}
              description={t('tuberculosis.content3')}
              onClick={() => navigate(langPath('/henkilotiedot'))}
              className="margin__bottom-pos--18"
              topContentClass={styles.altistuneetTopContent}
              icon={faHeartPulse}
            />
          </div>
        </div>
      }
    </div>
  );
}

export default TuberculosisPageContent;

import { createRef, useEffect } from "react";
import styles from './accessibilityReportContent.module.scss';
import commonStyles from "../../assets/styles/common.module.scss";
import { useTranslation } from "react-i18next";

const AccessibilityReportContent: React.FC = () => {
    const { t } = useTranslation();

    const initialFocus = createRef<any>();
    useEffect(() => { initialFocus.current?.focus(); }, []);

    return (
        <div className="container">
            <div className="container__row">
                <h1 ref={initialFocus} tabIndex={-1} className={`${commonStyles.title} margin__top-pos--30`}>{t('accessibilityReport.title')}</h1>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-1')}</p>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-2')}</p>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-3')}</p>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-4')}</p>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <h2 className={`${commonStyles.formLabelValue} ${styles.subtitle}`}>{t('accessibilityReport.subtitle-1')}</h2>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-5')}</p>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <h2 className={`${commonStyles.formLabelValue} ${styles.subtitle}`}>{t('accessibilityReport.subtitle-2')}</h2>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-6')}</p>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-7')}</p>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue} dangerouslySetInnerHTML={{ __html: t('accessibilityReport.text-8') }} />
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <h2 className={`${commonStyles.formLabelValue} ${styles.subtitle}`}>{t('accessibilityReport.subtitle-3')}</h2>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-9')}</p>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-10')}</p>
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-11')}</p>
                    <p className={commonStyles.formLabelValue} dangerouslySetInnerHTML={{ __html: t('accessibilityReport.text-12') }}/>
                    <p className={commonStyles.formLabelValue}><a href="mailto: saavutettavuus@avi.fi">{t('accessibilityReport.text-13')}</a></p>
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-14')}</p>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <h2 className={`${commonStyles.formLabelValue} ${styles.subtitle}`}>{t('accessibilityReport.subtitle-4')}</h2>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-15')}</p>
                </div>
            </div>
            <div className='container__row'>
                <div className="container__col-12">
                    <h2 className={`${commonStyles.formLabelValue} ${styles.subtitle}`}>{t('accessibilityReport.subtitle-5')}</h2>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <ul>
                        <li className={commonStyles.formLabelValue}>{t('accessibilityReport.text-16')}</li>
                    </ul>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-17')}</p>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <h2 className={`${commonStyles.formLabelValue} ${styles.subtitle}`}>{t('accessibilityReport.subtitle-6')}</h2>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-18')}</p>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-19')}</p>
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-20')}</p>
                    <p className={commonStyles.formLabelValue}>{t('accessibilityReport.text-21')}</p>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                <p className={commonStyles.formLabelValue} dangerouslySetInnerHTML={{ __html: t('accessibilityReport.text-22') }}/>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                <p className={commonStyles.formLabelValue} dangerouslySetInnerHTML={{ __html: t('accessibilityReport.text-23') }}/>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                    <h2 className={`${commonStyles.formLabelValue} ${styles.subtitle}`}>{t('accessibilityReport.subtitle-7')}</h2>
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                <p className={commonStyles.formLabelValue} dangerouslySetInnerHTML={{ __html: t('accessibilityReport.text-24') }} />
                </div>
            </div>
            <div className='container__row margin__bottom-pos--18'>
                <div className="container__col-12">
                </div>
            </div>
        </div>
    )
}

export default AccessibilityReportContent;
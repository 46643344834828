import React from 'react';
import styles from './breadcrumb.module.scss';

interface IBreadcrumb {
  step: number;
  text: string;
  selected?: boolean;
  wrapperClassName?: string;
  numberClassName?: string;
  iconClassName?: string;
  onClick?: () => void;
}

const Breadcrumb: React.FC<IBreadcrumb> = ({
  step,
  text,
  selected,
  wrapperClassName,
  numberClassName,
  iconClassName,
  onClick,
}) => {
  return (
    <div
      tabIndex={0}
      role="button"
      className={`${styles.breadcrumsWrapper} ${
        onClick ? styles.clickable : ''
      } ${wrapperClassName}`}
      onClick={() => (onClick ? onClick() : null)}
      onKeyDown={(e) => {
        if(e.key === 'Enter' && onClick != null) {
          onClick();
        };
      }}
    >
      <div className={`${styles.breadcrumLeft}`}>
        <div
          className={`${styles.number} ${
            selected ? styles.selected : ''
          } ${numberClassName}`}
        >
          {step}
        </div>
      </div>
      <div className={styles.breadcrumMiddle}>
      </div>
      <div className={styles.data}>{text}</div>
      <div className={styles.breadcrumRight}>&nbsp;</div>
    </div>
  );
};

Breadcrumb.defaultProps = {
  selected: false,
  wrapperClassName: '',
  numberClassName: '',
  iconClassName: '',
  onClick: undefined,
};

export default Breadcrumb;

import {  createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LoadingStatus from '../../models/enums/LoadingStatus';
import { IMaintenance } from '../../models/IMaintenance';
import maintenanceService from '../../services/maintenanceService';

export interface MaintenanceState {
    hasData: boolean;
    data: IMaintenance[];
    hasError: boolean;
    error: string;
    status: LoadingStatus;
}

const initialState: MaintenanceState = {
    hasData: false,
    data: [],
    hasError: false,
    error: '',
    status: LoadingStatus.Idle,
}

export const fetchMaintenanceData = createAsyncThunk(
  'maintenance/fetchMaintenanceData',async(_, thunkAPI) => {
    try {
      return await maintenanceService.getMaintenanceData();
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data })
    }
  }
)

export const maintenanceSlice = createSlice({
    name: 'maintenance',
    initialState,
    reducers: {},
    extraReducers: (builder => {
      builder.addCase(fetchMaintenanceData.pending, (state, action) => {
        state.status = LoadingStatus.Loading
        state.hasError = false;
        state.error = '';
        });
      builder.addCase(fetchMaintenanceData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.hasData = true;
        state.status = LoadingStatus.Idle;
      });
      builder.addCase(fetchMaintenanceData.rejected, (state, action) => {
        state.status = LoadingStatus.Idle;
        state.hasError = true;
        state.hasData = false;
        state.data = [];
        state.error = action.error.message ?? '';
      });
    })
})

export default maintenanceSlice.reducer
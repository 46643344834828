import { IOption } from "../models/IOption";


const yearOptions: IOption[] = [];

const thisYear = new Date().getFullYear();
for (let i = thisYear; i > (thisYear - 100); i--) {
    yearOptions.push({ label: i.toString(), value: i.toString() } as IOption);
}

export default yearOptions;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import SingoutOidc from '../../features/signoutOidc/signoutOidc';
import LoggedInUser from '../../models/LoggedInUser';
import { UserType } from '../../models/UserType';
import { INVALID_USER_KEY } from '../../shared/constants/logoutConstants';
import { setIsProfessional, setLoggedIn } from '../../state/settings/settingsSlice';
import { fetchSurveillanceDetailsAsync, fetchUserDetailsAsync } from '../../state/userInfo/userSlice';
import { langPath } from '../../utils/pathUtils';
import userManager from '../../utils/userManager';
import { logoutHelper } from '../../utils/userManagerHelpers';
import LoadingStatus from '../../models/enums/LoadingStatus';
import { fetchCommonData, fetchTuberculosisFormData } from '../../state/forms/formsSlice';
import { RootState } from '../../state/store';

const IsConsentRoute: React.FC<any> = () => {
  const dispatch = useDispatch()<any>;
  const settings = useSelector((state: RootState) => state.settings);
  const userDetails = useSelector((state: RootState) => state.userDetails);

  useEffect(() => {
    let subscribe = true;
    if (!subscribe) return;

    const fetchData = async () => {
      const user = await userManager.getUser();

      if (!subscribe) return;

      if (user?.profile.usertype === UserType.Professional) {
        dispatch(setIsProfessional(true));
      }
      if (!user) {
        dispatch(setLoggedIn(LoggedInUser.NO));
      } else {
        if (settings.isProfessional) {
          await logoutHelper(INVALID_USER_KEY, user);
        } else {
          dispatch(setLoggedIn(LoggedInUser.YES));
        }
      }
    }
    fetchData();

    return () => {
      subscribe = false;
    };
  }, []);

  useEffect(() => {
    let subscribe = true;
    if (!subscribe) return;

    if (userDetails.fetchingUserDetails === LoadingStatus.Idle) {
      dispatch(fetchUserDetailsAsync());
      dispatch(fetchSurveillanceDetailsAsync('tuberculosis'));
    }

      if (userDetails.fetchingUserDetails === LoadingStatus.Succeeded && userDetails.fetchingSurveillanceDetails === LoadingStatus.Succeeded) {
      dispatch(fetchCommonData());
      dispatch(fetchTuberculosisFormData());

    }

    return () => {
      subscribe = false;
    };
  }, [userDetails.fetchingUserDetails, userDetails.fetchingSurveillanceDetails])

  if (settings.isProfessional) {
    return <SingoutOidc />
  }

  if (settings.loggedIn === LoggedInUser.YES) {
    return <Outlet />
  }

  if (settings.loggedIn === LoggedInUser.UNKNOWN) {
    return <div />;
  }

  if (settings.loggedIn === LoggedInUser.NO) {
    return <Navigate to={langPath('/')} />
  }


  return <div />;
};

export default IsConsentRoute;

import {  createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LoadingStatus from '../../models/enums/LoadingStatus';
import FormType from '../../models/FormType';
import { ICountry } from '../../models/ICountry';
import { ILanguage } from '../../models/ILanguage';
import { IMunicipality } from '../../models/IMunicipality';
import { IOption } from '../../models/IOption';
import { TuberculosisForm } from '../../models/TuberculosisForm';
import commonDataService from '../../services/commonDataService';
import { getQuestionnaireResponse } from '../../services/questionnaireService';
import { getCurrentLang } from '../../utils/languageUtils';
import setLanguageVariation from '../../utils/setLanguageVariation';

export interface FormsState {
    formType: FormType;
    tuberculosisForm: TuberculosisForm;
    municipalities: IOption[];
    countries: IOption[];
    languages: IOption[];
    status: string;
    formDataLoaded: boolean;
}

const initialState: FormsState = {
    formType: FormType.PERSONAL_INFO,
    tuberculosisForm: {},
    municipalities: [],
    countries: [],
    languages: [],
    status: LoadingStatus.Idle,
    formDataLoaded: false,
}

export const fetchTuberculosisFormData = createAsyncThunk(
  'forms/fetchTuberculosisFormData',async(_, thunkAPI) => {
    try {
      return await getQuestionnaireResponse('tuberculosis');
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data })
    }
  }
)

export const fetchCommonData = createAsyncThunk(
  'forms/setCommonData',async (_, thunkAPI) => {
    try {
      return await commonDataService.getCommonData();
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.data })
    }
})

export const settingsSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        setFormType: (state, action) => {
            state.formType = action.payload;
        },
        setTuberculosisInfo: (state, action) => {
            state.tuberculosisForm = { ...state.tuberculosisForm, ...action.payload };
        },
        setMunicipalities: (state, action) => {
            const lang = getCurrentLang();
            state.municipalities = action.payload.map((municipality: IMunicipality) => {
                return { label: lang === 'sv' ? municipality.seName : municipality.fiName, value: municipality.municipalityId }
            })
        },
    },
    extraReducers: (builder => {
      builder.addCase(fetchTuberculosisFormData.pending, (state, action) => {
        state.status = LoadingStatus.Loading
        });
      builder.addCase(fetchTuberculosisFormData.fulfilled, (state, action) => {
        state.tuberculosisForm = { ...state.tuberculosisForm, ...action.payload };
        state.formDataLoaded = true;
        state.status = LoadingStatus.Idle
      });
      builder.addCase(fetchTuberculosisFormData.rejected, (state) => {
        state.status = LoadingStatus.Idle;
        });
      builder.addCase(fetchCommonData.fulfilled, ( state, action) => {
        action.payload?.municipalities.forEach((municipality: IMunicipality) => {
          state.municipalities = state.municipalities.concat([ { label: setLanguageVariation(municipality) ?? '', value: municipality.municipalityId} ]);
        })
        action.payload?.countries.forEach((country: ICountry) => {
          state.countries = state.countries.concat([ { label: setLanguageVariation(country) ?? '', value: country.countryId} ]);
        })
        action.payload?.languages.forEach((language: ILanguage) => {
          state.languages = state.languages.concat([ { label: setLanguageVariation(language) ?? '', value: language.languageId} ]);
        })
      })
    })
})

export const { setFormType, setTuberculosisInfo, setMunicipalities } = settingsSlice.actions

export default settingsSlice.reducer

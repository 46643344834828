import React, { PropsWithChildren } from 'react';
import MaintenancePageContent from '../../features/maintenancePageContent/maintenancePageContent';

interface MaintenancePageProps {}

const MaintenancePage: React.FC<PropsWithChildren<MaintenancePageProps>> = ({
    children,
}) => {
    return (
        <MaintenancePageContent>
            {children}
        </MaintenancePageContent>
    );
};

export default MaintenancePage;
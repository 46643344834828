import { createRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ActionBox from "../../components/actionBox/actionBox";
import { langPath } from "../../utils/pathUtils";
import styles from "./pageNotFound.module.scss";

const PageNotFoundContent: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('pageNotFound.title')
    }, []);


    const initialFocus = createRef<any>();
    useEffect(() => { initialFocus.current?.focus(); }, []);

    return (
        <div className={`container ${styles.pageContainer}`}>
            <h1>{t('pageNotFound.404')}</h1>
            <p ref={initialFocus} tabIndex={-1} className={styles.title}>{t('pageNotFound.header')}</p>
            <p className={styles.content}>{t('pageNotFound.content')}</p>
            <div className={styles.actionBox}>
                <ActionBox
                    title={t('pageNotFound.actionBoxTitle')}
                    onClick={() => navigate(langPath('/tuberkuloosi'))}
                />
            </div>
        </div>
    );
}
export default PageNotFoundContent;
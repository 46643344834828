import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ActionBox from '../../components/actionBox/actionBox';
import { langPath } from '../../utils/pathUtils';

interface MaintenancePageContentProps {} 

const MaintenancePageContent: React.FC<PropsWithChildren<MaintenancePageContentProps>> = ({
    children
}) => {
  const { t } = useTranslation();
  return (
    <div className="container padding__bottom-pos--50">
      <div className="container__row">
        <div className="container__col-12">
          <h2>{t('maintenancePage.heading')}</h2>
        </div>
      </div>
      <div className="container__row margin__bottom-pos--60">
        <div className="container__col-12">
          {children}
        </div>
      </div>
      
    </div>
  );
};

export default MaintenancePageContent;
import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import LoadingStatus from '../../models/enums/LoadingStatus';
import { RootState } from "../../state/store";
import { langPath } from '../../utils/pathUtils';
import NotificationBar from '../../components/notificationBar/notificationBar';
import styles from "./header.module.scss";
import LanguageButtons from "../languageButtons/languageButtons";
import LogOutButton from '../logoutButton/logout';
import { getCurrentLang } from '../../utils/languageUtils';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { t } = useTranslation();
  const userData = useSelector((state: RootState) => state.userDetails);
  const settings = useSelector((state: RootState) => state.settings);
  const location = useLocation();

  return (
    <>
      {settings.isOnBehalf && userData.fetchingUserDetails === LoadingStatus.Succeeded &&
        <NotificationBar
          textA={t('header.actOnBehalfTextA')}
          textB={t('header.actOnBehalfTextB')}
          userFullName={userData.userDetails?.firstName + " " + userData.userDetails?.lastName}
        />
      }
      <div className={settings.isOnBehalf && userData.fetchingUserDetails === LoadingStatus.Succeeded ? `container ${styles.marginTopOnBehalf}` : `container`}>
        <div className={styles.headerContainer}>
          <div className={styles.headerContent}>
            <div>
              <a href={`/${getCurrentLang() === "fi" ? "" : getCurrentLang()}`} className={styles.headerLink}>
                <h1 className={styles.headerTitle}>{t("header.title")}</h1>
              </a>
            </div>
            <div className={styles.headerButtons}>
              {location.pathname !== langPath('/henkilotiedot') &&
                <LanguageButtons />
              }
              <LogOutButton />
            </div>
          </div>
        </div>
      </div>
      <div>
        <hr className={styles.dividerHeader} />
      </div>
    </>
  );
};

export default Header;

import React, { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import onBehalfErrorSelector from '../../utils/urlHelpers';
import styles from './onBehalfError.module.scss';

const OnBehalfError: React.FC = () => {
  const errorKey = onBehalfErrorSelector(window.location.search);
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t('header.title')
  }, []);

  const initialFocus = createRef<any>();
  useEffect(() => { initialFocus.current?.focus(); }, []);

  return (
    <div className="container__row">
      <div ref={initialFocus} tabIndex={-1} className={`${styles.content} container__col-12`}>
        {t(`onBehalfError.${errorKey}`)}
      </div>
    </div>
  );
};

export default OnBehalfError;

import React, { RefObject } from 'react';
import PropTypes from 'prop-types';

interface TWithLinkProps {
  text: string;
  className?: string;
  textContainerRef?: RefObject<HTMLDivElement>
}

const TWithLink: React.FC<TWithLinkProps> = ({ text, className, textContainerRef }) => {
  return (
    <div
      className={className ?? ''}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: text,
      }}
      ref={textContainerRef}
      tabIndex={textContainerRef ? -1 : undefined}
    />
  );
};

export default TWithLink;

TWithLink.propTypes = {
  text: PropTypes.string.isRequired,
};

import { t } from "i18next";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import FrontpageContent from "../../features/frontpage/frontpageContent";
import LoggedInUser from "../../models/LoggedInUser";
import { setLoggedIn } from "../../state/settings/settingsSlice";
import { RootState } from "../../state/store";
import { langPath } from "../../utils/pathUtils";
import userManager from "../../utils/userManager";

const Frontpage: React.FC = () => {
  const loggedIn = useSelector((state: RootState) => state.settings.loggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = t('header.title')
  }, []);

  useEffect(() => {
    let subscribe = true;

    if (!subscribe) return;
    async function fetchData() {
      const user = await userManager.getUser();

      if (user === null) {
        dispatch(setLoggedIn(LoggedInUser.NO));
      } else {
        dispatch(setLoggedIn(LoggedInUser.YES));
      }
    }
    fetchData();

    return () => {
      subscribe = false;
    };
  }, []);

  if (loggedIn === LoggedInUser.UNKNOWN) {
    return <div />;
  }

  if (loggedIn === LoggedInUser.YES) {
    return <Navigate to={langPath('/tuberkuloosi')} />
  }

  if (loggedIn === LoggedInUser.NO) {
    return <>
      <FrontpageContent />
    </>
  }
  return <div />;
};

export default Frontpage;
